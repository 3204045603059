import React, { useState } from 'react';
import './Settings.css'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Container, Paper } from '@mui/material';
import useInfo from '../../hooks/useInfo';
import { useForm } from 'react-hook-form';
import useSettings from '../../hooks/useSettings';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { Badge, Col, Row } from 'react-bootstrap';
import useTemplates from '../../hooks/useTemplates';
import AllTemplates from '../AllTemplates/AllTemplates';
import SingleTemplate from '../SingleTemplate/SingleTemplate';
import SettingsIcon from '@mui/icons-material/Settings';
import dotdot from '../../visuals/dotdot.gif';
import useDataInfo from '../../database/useDataInfo';
const Setting = () => {
    const [expanded, setExpanded] = useState(false);
    const { templates, loading } = useTemplates();
    if (templates?.length > 0) {
        var id = templates[0]._id
        // console.log(templates[0]._id)
    }
    const { addSetting } = useDataInfo();
    const [template, setTemplate] = useState(id || 0);

    // console.log(template, 'default tempate')
    // const { isAutoRespond, setIsAutoRespond } = useInfo();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const { settings, setSettings, delay, setDelay, isAutoRespond, setIsAutoRespond, defaultTemplate, setDefaultTemplate } = useSettings();
    const defaultTemplateContent = templates?.find(t => t._id === defaultTemplate);
    // console.log('defaultTemplateReply', defaultTemplateContent)
    // const [delay, setDelay] = useState(60);
    const { register, handleSubmit } = useForm();
    const onDefaultDelaySubmit = data => {
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        setDelay(data.delay)
        tempSetting['delay'] = data.delay;
        addSetting(tempSetting, settings[0]?._id);
    };
    const onSecondDefaultDelaySubmit = data => {
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        setDelay(data.delay2)
        tempSetting['delay2'] = data.delay2;
        console.log('data:', data);
        addSetting(tempSetting, settings[0]?._id);
    };
    const onThirdDefaultDelaySubmit = data => {
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        setDelay(data.delay3)
        tempSetting['delay3'] = data.delay3;
        addSetting(tempSetting, settings[0]?._id);
    };
    const onSubmit = data => {
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        // console.log('from onSubmit:', data)
        setDelay(data.delay)
        setTemplate(data.template);
        data['isAutoRespond'] = isAutoRespond;
        data['template'] = template;
        data['defaultTemplate'] = defaultTemplate;
        // addSetting(data, settings[0]?._id);
        console.log('after add', data);

    };
    const handleIsAutoRespondSubmit = () => {
        setIsAutoRespond(!isAutoRespond);
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        tempSetting['isAutoRespond'] = !isAutoRespond;
        addSetting(tempSetting, settings[0]?._id);
    }
    const handleDefaultTemplateSelection = (id) => {
        setTemplate(id);
    }
    return (
        <Container style={{ padding: "25px 0" }}>
            <Paper sx={{ width: '100%', p: 1, my: 1 }}>
                <Row>
                    <Col xs={12} md={6} className="p-0">
                        <Container className="sub-title text-left">
                            <Row>
                                <Col md={6}>
                                    <h2>Settings <SettingsIcon /></h2>
                                </Col>
                                {/* <Col md={2}><span className="bdemr-badge d-flex mt-2">{contactsCount}</span></Col> */}
                                <Col xs={6} md={2} className="p-0 d-flex justify-content-end align-items-center">
                                    {/* <SettingsIcon /> */}
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Paper>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Auto Response settings 
                        {isAutoRespond ?
                            <ScheduleSendIcon style={{ fontSize: 24, marginLeft: '20px', color: "#198754" }} title="Auto Response" /> :
                            <CancelScheduleSendIcon style={{ fontSize: 24, marginLeft: '20px', color: "#198754" }} title="Auto Response" />

                        }
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {isAutoRespond ? <span>Turn off auto response</span> : <span> Turn on auto respond</span>}

                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button onClick={() => handleIsAutoRespondSubmit()}>
                        {isAutoRespond ? 'Turn off' : 'Turn on'}
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Delay settings {delay ? <Badge pill bg="success" style={{ marginRight: '20px' }} >{delay} sec</Badge> : <img src={dotdot} alt="loading" className="dotLoader" />}
                    </Typography> 
                    <Typography sx={{ color: 'text.secondary' }}>Change Delay Time  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className="services-container">
                        <form onSubmit={handleSubmit(onDefaultDelaySubmit)} className="delay-form">
                            <p>Default Reply delay Time (Seconds):</p>
                        <input type="number" {...register("delay", { min: 0, max: 100000 })} placeholder="Enter Delay Time" />
                            <Button type="submit" variant="contained" className="w-100" color="success">SET</Button>
                    </form>
                        <form onSubmit={handleSubmit(onSecondDefaultDelaySubmit)} className="delay-form">
                            <p>Second Default delay Time (Minuites):</p>
                            <input type="number" {...register("delay2", { min: 0, max: 1000000000000 })} placeholder={settings[0]?.delay2} />
                            <Button type="submit" variant="contained" className="w-100" color="success">SET</Button>
                        </form>
                        <form onSubmit={handleSubmit(onThirdDefaultDelaySubmit)} className="delay-form">
                            <p>Third Default delay Time (Minuites):</p>
                            <input type="number" {...register("delay3", { min: 0, max: 100000000000000 })} placeholder={settings[0]?.delay3} />
                            <Button type="submit" variant="contained" className="w-100" color="success">SET</Button>
                        </form>
                    </Box>

                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Template Settings</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Select or change template
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AllTemplates templates={templates}></AllTemplates>
                    {/* <form onSubmit={handleSubmit(onSubmit)} className="px-5">
                        <input type="number" {...register("template", { min: 0, max: 3 })} placeholder="Enter Template number" />
                        <input type="submit" placeholder="set" />
                    </form> */}
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Default Reply</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Template name: {defaultTemplateContent?.template_name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Typography>
                        Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                        amet egestas eros, vitae egestas augue. Duis vel est augue.
                    </Typography> */}
                    <div className="single-template-container">
                        <SingleTemplate
                            temp={defaultTemplateContent}
                        ></SingleTemplate>
                    </div>

                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

export default Setting;