import React from 'react';
import { setShow } from '../componenets/Contacts/Contacts/Contacts';
import useAuth from '../hooks/useAuth';



const useDataInfo = () => {
    const { setContactLoading, user } = useAuth();
    const addContactToDB = (data) => {

        console.log('contact::::', data);
        // fetch(`http://localhost:7002/contacts`, {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                // modifiedCount: 0, upsertedId: null, upsertedCount: 0
                if (data.modifiedCount > 0 || data.upsertedCount > 0) {
                    console.log('Contact added Successful', data);
                    setContactLoading(false);
                    alert('Uploaded Successfully')
                    return true
                }
                else {
                    console.log('Already exist');
                    setContactLoading(false);
                    return false
                }
            });

    }
    const addEmailsToDB = (settings) => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/allEmails`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(settings)
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0) {
                    console.log('email added Successful');
                }
                else {
                    console.log('Already exist')
                }
            });

    }
    const addSetting = (setting, id) => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/settingInfo/${id}?email=${user?.email}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(setting)
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0 || data.modifiedCount > 0) {
                    alert('Update Successful');
                }
                else {
                    alert('Some Thing went wrong')
                }
            })
            .catch(err => {
                console.log(err);
                alert('something went wrong! Please try again')
            });

    }
    const addThreadCount = (email_threads) => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/threads`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(email_threads)
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0) {
                    console.log('thread updated Successful');
                }
                else {
                    console.log('Failed')
                }
            })
            .catch(err => console.log(err))

    }
    // const addToThreadDb = (tId, id) => {
    //     const exists = getDb();
    //     let email_threads = {};
    //     if (!exists) {
    //         email_threads[tId] = 1;
    //     }
    //     else {
    //         email_threads = JSON.parse(exists);
    //         if (email_threads[tId]) {
    //             const newCount = email_threads[tId] + 1;
    //             email_threads[tId] = newCount;
    //             console.log('Exists');
    //         }
    //         else {
    //             email_threads[tId] = 1;
    //         }
    //     }
    //     updateDb(email_threads);
    // }
    // const addSetting = (delay) => {
    //     console.log('Delay from dataInfo:', delay);
    //     const data = {
    //         delay: delay
    //     }
    //     axios.post('${process.env.REACT_APP_SERVERHOST}:7002/settingInfo', data)
    //         .then(res => {
    //             if (res.data.insertedId) {
    //                 alert('added successfully');
    //                 // reset();
    //             }
    //         })

    // }
    return { addContactToDB, addSetting, addEmailsToDB, addThreadCount };
};

export default useDataInfo;