import React from 'react';

const Sent_SingleEmail = ({ message }) => {
    const mail = message.payload;
    let senderName = mail?.headers.find(item => item.name.toLowerCase() === 'from') || 'SENDER';
    let displayName = 'No Title Found';
    let senderEmail = '';
    if (senderName.value.includes('<')) {
        displayName = senderName.value.split('<')[0];
        const senderEmailStr = senderName.value.split('<')[1];
        senderEmail = senderEmailStr.split('>')[0];
    }
    else {
        senderEmail = senderName.value;
    }

    const subject = mail?.headers.find(item => item.name.toLowerCase() === 'subject') || 'SUBJECT';
    const date = mail?.headers.find(item => item.name.toLowerCase() === 'date') || 'DATE';
    return (
        <tr>
            <td colSpan="2" className="text-left">
                <strong>{displayName?.slice(0, 25)}</strong>
                {/* <strong>Sender</strong> */}
                <small> {senderEmail}</small>
                {/* <small> email</small> */}
            </td>

            <td colSpan="2" className="mail-subject">{subject?.value?.slice(0, 40)}- {mail?.snippet?.slice(0, 40)}</td>
            {/* <td colSpan="3" className="mail-subject">subject</td> */}
            <td><small>{date.value.slice(4, 16)}</small></td>
            {/* <td><small>coming soon ....</small></td> */}
            <td>
                coming soon
            </td>

        </tr>
    );
};

export default Sent_SingleEmail;