import { useEffect, useState } from "react";
import useAuth from "./useAuth";
// import Swal from "sweetalert2";

const useWallet = (email) => {
  const [allUserWalletLogs, setAllUserWalletLogs] = useState(0);
  const [myWallet, setMyWallet] = useState();
  const [walletLoading, setwWlletLoading] = useState();
  useEffect(() => {
    setwWlletLoading(true);
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/wallet`)
      .then((res) => res.json())
      .then((data) => {
        setAllUserWalletLogs(data);
        let myWalletData = data.find((item) => item.userEmail === email);
        console.log("allUserWalletLogs:", data);
        setMyWallet(myWalletData);
        setwWlletLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setwWlletLoading(false);
      });
  }, []);
  return { allUserWalletLogs, myWallet, setMyWallet, walletLoading };
};

export default useWallet;
