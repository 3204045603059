import MailIcon from '@mui/icons-material/Mail';
import { Badge, Paper } from '@mui/material';
import React, { useState } from 'react';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { Routes } from 'react-router-dom';
import useEmails from '../../hooks/useEmails';
import useThreads from '../../hooks/useThreads';
import Sent_SingleEmail from '../Sent_SingleEmail/Sent_SingleEmail';

const Sent = () => {
    const { mails } = useEmails('SENT');
    let tempMails = [];
    console.log('sent: ', mails);
    const [show, setShow] = useState(false);
    const { threads } = useThreads();
    mails?.map(item => {
        if (item.labelIds.includes('SENT')) {
            tempMails.push(item);
            console.log('SENT message: ', item);
        } 
    }
    );
    const [emailData, setEmailData] = useState({});
    // const [sentEmailData, setSentEmailData] = useState({});
    // const mailData = new Buffer(mails.parts[0].body.data, 'base64').toString();
    // const contacts = ['sabujx9@gmail.com'];
    const handleEmailDetails = (data) => {
        setEmailData(data)
    }
    mails.sort((dateA, dateB) => dateB.internalDate - dateA.internalDate);

    // const handleSendToAll = () => {
    //     contacts.map(userEmail => {
    //         if (userEmail.includes('noreply') && userEmail.includes('no-reply')) {
    //             console.log('Reply is not allowed');
    //         }
    //         else {
    //             console.log(userEmail)
    //             const url = `${process.env.REACT_APP_SERVERHOST}:7002/respond/${userEmail}`;
    //             fetch(url, {
    //                 method: 'POST',
    //                 headers: {
    //                     "content-type": "application/json"
    //                 },
    //                 // body: JSON.stringify(userEmail)
    //             })
    //                 .then(res => res.json())
    //                 .then(data => {
    //                     if (data) {
    //                         alert('Sent Successfully', data);
    //                     }
    //                     else {
    //                         alert('failed')
    //                     }
    //                 })
    //         }
    //     })
    // }
    console.log('tempMails>>>>>>>>', tempMails);
    return (
        <div className="container mt-3">
            <Row className="m-2">
            <Paper elevation={2} sx={{ py: 1, mt: 1 }}>
            <Row className="m-2">
                    <Col xs={6} md={2}>
                        <Row>
                            <Col md={9} className="sub-title text-left">
                                <h2>Sent</h2>
                            </Col>
                            <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                                <Badge badgeContent={tempMails?.length} color="success">
                                    <MailIcon color="action" />
                                </Badge>
                            </Col>
                        </Row>
                    </Col>
                <Col xs={12} md={8} className="p-0 d-flex justify-content-end align-items-center">
                </Col>
            </Row>
            </Paper>
            </Row>
            <div className="bg-white">
                <Table hover>
                    <thead>
                        <tr>
                            <th colSpan="2">Sender</th>
                            <th colSpan="2">Subject</th>
                            <th>Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // show mails in the sent mail box
                            tempMails?.length > 0 ? tempMails?.map(mail => 
                            // eslint-disable-next-line react/jsx-pascal-case
                                <Sent_SingleEmail
                                    key={mail.id}
                                    message={mail}
                                ></Sent_SingleEmail>) :
                                <div className="text-center my-5 private-spinner py-5">
                                    <Spinner variant="success" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <h6>Loading...</h6>
                                </div>
                        }
                    </tbody>
                </Table>
            </div>
            <Routes>
                {/* <Route path="/" element={<Inbox mails={mails} handleEmailDetails={handleEmailDetails}></Inbox>} /> */}
                {/* <Route path="about/:id" element={<EmailDetailed emailData={emailData}></EmailDetailed>} /> */}
            </Routes> 
        </div>
    );
};

export default Sent;