const addToDb = (mailId) => {
  const exists = getDb();
  let loacl_mails = {};
  if (!exists) {
    loacl_mails[mailId] = 'UNREAD';
  }
  else {
    loacl_mails = JSON.parse(exists);
    if (loacl_mails[mailId]) {

    }
    else {
      loacl_mails[mailId] = 'UNREAD';
    }
  }
  updateDb(loacl_mails);
}
const updateStatus = mailId => {
  let loacl_mails = JSON.parse(getDb());
  loacl_mails[mailId] = 'READ';
  updateDb(loacl_mails);
}
const updateSentStatus = mailId => {
  let loacl_mails = JSON.parse(getDb());
  loacl_mails[mailId] = 'SENT';
  updateDb(loacl_mails);
}
const getDb = () => localStorage.getItem('loacl_mails');

const updateDb = mails => {
  localStorage.setItem('loacl_mails', JSON.stringify(mails));
}

const removeFromDb = mailId => {
  const exists = getDb();
  if (!exists) {

  }
  else {
    const loacl_mails = JSON.parse(exists);
    delete loacl_mails[mailId];
    updateDb(loacl_mails);
  }
}

const getStoredMails = () => {
  const exists = getDb();
  return exists ? JSON.parse(exists) : {};
}

const clearTheLocalMails = () => {
  localStorage.removeItem('loacl_mails');
}

export { addToDb, removeFromDb, clearTheLocalMails, getStoredMails, updateStatus, updateSentStatus }