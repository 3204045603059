import { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, getIdToken, updateProfile } from "firebase/auth";
import initializeAuthentication from '../Firebase/firebase.init';
import axios from 'axios';
// import useWatches from './useWatches';

initializeAuthentication();

const useFirebase = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [reload, setReload] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [totalInboxMailCount, setTotalInboxMailCount] = useState(0);
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  
  const requestImapResponse = (email, pass) => {

}
  const signInUsingGoogle = (location, history) => {
    setIsLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        //TO DO: NEED UPDATE
        addUserTodatabase(user.displayName, user.email, "PUT");
        setError("");
        const destination = location?.state?.from || "/";
        history.replace(destination);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => setIsLoading(false));
  };
  const registerWithEmail = (name, email, host, password, history) => {
    // addUserTodatabase(name, email, host, password, 'PUT');
    setIsUploading(true);
    // console.log(name, email, host, password);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const registeredUser = { email, displayName: name };
        setError("");
        //     setUser(userCredential);
        updateProfile(auth.currentUser, {
          displayName: name,
        })
          .then(() => {
            // TO DO: NEED UPDATE
            // console.log("USER::::::::", user);
            addUserTodatabase({ name, email, host, password, method: "PUT" });
          })
          .catch((error) => {
            setError(error.message);
            setIsUploading(false);
          });

        // history.replace('/');
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
        setIsUploading(false);
      });
  };
  const loginUser = (email, password, location, history) => {
    setIsLoading(true);
    console.log(location, email, password);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const destination = location?.state?.from || "/dashboard";
        // history.replace(destination);
        // setUser(userCredential);
        setError("");
        requestImapResponse(email, password);
        console.log("userCredential", userCredential);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error.message);
      })
      .finally(() => setIsLoading(false));
  };
  const logOut = () => {
    setIsLoading(true);
    signOut(auth)
      .then(() => {
        setUser({});
      })
      .finally(() => setIsLoading(false));
  };

  // observe whether user auth state changed or not
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        getIdToken(user).then((idToken) =>
          localStorage.setItem("idToken", idToken)
        );
      } else {
        setUser({});
      }
      setIsLoading(false);
    });
    return () => unsubscribe;
  }, []);
  // const addUserTodatabase=(displayName, email)=>{
  //   const data = {displayName, email};
  //   axios.post('https://young-ocean-72177.herokuapp.com/users', data)
  //           .then(res => {
  //               if (res.data.insertedId) {
  //                   alert('User Added to database successfully');
  //                   // reset();
  //               }
  //           });
  // }
  const addUserTodatabase = (userData) => {
    // const user = { name, email, host, password };
    const method = userData?.method;
    delete userData.method;
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/users`, {
      method: method || "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) setIsUploading(false);
        if (data.modifiedCount > 0) {
          setAlertMessage("User Updated Successfully");
          return "User Updated Successfully";
        } else if (data.upsertedCount > 0 || data.upsertedId) {
          setAlertMessage("User Added Successfully");
          return "User Added Successfully";
        }
        console.log("user api hitted:", data);
      })
      .catch((err) => {
        console.log(err);
        setIsUploading(false);
      });
  };
  const verifyUserLogin = (email, password) => {
    const user = { email, password };
    let userInfo = {};
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data._id) {
          const userInfo = {
            email: data.email,
            displayName: data.displayName || "",
          };
          setUser(userInfo);
        } else {
          setError(data.error);
        }

        userInfo = data;
      });
    return userInfo;
  };
  const refreshPage = () => {
    window.location.reload(false);
  }

  // const removeTemplate=(id)=>{
  //   const confirmDelete = window.confirm('Are you sure?');
  //   if (confirmDelete) {
  //       const url = `https://young-ocean-72177.herokuapp.com/watches/${id}`;
  //       fetch(url, {
  //           method: 'DELETE'
  //       })
  //           .then(res => res.json())
  //           .then(data => {
  //               console.log(data);
  //               if (data.deletedCount) {
  //                   const remaingWatches = watches.filter(data => data._id !== id);
  //                   setWatches(remaingWatches)
  //               }
  //           })
  //   }
  // }
  let errorMsg = error;
  if (errorMsg?.includes('auth')) {
    console.log('yes')
    errorMsg = errorMsg.split('/')[1];
    errorMsg = errorMsg.split(')')[0];
    setError(errorMsg);
  }
  return {
    user,
    setUser,
    isLoading,
    isUploading, setIsUploading,
    signInUsingGoogle,
    logOut,
    registerWithEmail,
    loginUser,
    addUserTodatabase,
    refreshPage,
    verifyUserLogin,
    error, setError,
    reload, setReload,
    contactLoading, setContactLoading,
    totalInboxMailCount, setTotalInboxMailCount,
    alertMessage, setAlertMessage 
  }
}

export default useFirebase;