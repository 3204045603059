import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
function ContactTable() {
    const [onSiteContact, setOnSiteContact] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([
    ]);
    const { user, reload } = useAuth();
    // const { contacts, contactsCount } = useContacts();
    const columns = [
        {
            title: "Name", field: "displayName", sorting: false, filtering: false, width: "15%"
        },
        {
            title: "Email", field: "email", sorting: false, filtering: false, editable: true, width: "35%"
        },
    ];
    // useEffect(() => {
    //     fetch(`http://localhost:5000/students?page=${page}&&size=${rowsPerPage}`)
    //         .then(res => res.json())
    //         .then(data => {
    //             setOnSiteContact(data.students);
    //             setTableData(data.students);
    //             const count = data.count;
    //             const pageNumber = Math.ceil(count / rowsPerPage);
    //             setPageCount(pageNumber);
    //         });
    // }, [page]);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts/?email=${user.email}`)
            .then(res => res.json())
            .then(data => {
                setOnSiteContact(data[0]?.emails);
                setTableData(data[0]?.emails)
            })
            .catch((err) => console.log(err));
    }, [page]);
    // console.log('contacts:', onSiteContact);
    const handleStudentAdd = (data) => {
        // console.log('id::::', data);
        axios.post('http://localhost:5000/students', data)
            .then(res => {
                if (res.data.insertedId) {
                    alert('added successfully');
                }
            })
    }
    const handleStudentUpdate = (data) => {
        console.log('data::::', data);
        const tempData = { ...data };
        delete tempData._id;
        console.log('tempData::::', tempData);
        fetch(`http://localhost:5000/students/${data._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(tempData)
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0 || data.modifiedCount) {
                    alert('Updated Successfully');
                }
                else {
                    alert('Something went wrong')
                }
            });
    }
    const handleBulkDelete = (data) => {
        const updatedData = tableData.filter(row => !selectedRows.includes(row));
        console.log('updatedData', updatedData);
        let ids = [];
        const confirmDelete = window.confirm('Are you sure?');
        if (confirmDelete) {
            data.map(item => {
                // const objID = `ObjectId("${item._id}")`
                // ids.push(objID)
                const deleted = removeContact(item);
                // const deleted = removeStudent(item._id);
                console.log('deleted', deleted)
                setTableData(updatedData);
            })
        }

    }

    // updatedData.splice(selectedRow.tableData.id, 1)
    // setTableData(updatedData)
    // setTimeout(() => resolve(), 1000)
    // }

    const removeContact = (contact) => {
        console.log(contact);
        // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts`, {
        fetch(`http://localhost:7002/contacts`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(contact)
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Contact added Successful');
                    // setShow(true)
                    return true
                }
                else {
                    console.log('Already exist')
                    return false
                }
            });

    }
    return (
        <>
            <MaterialTable columns={columns} data={tableData}
                editable={{
                    onRowAdd: (newRow) => new Promise((resolve, reject) => {
                        handleStudentAdd(newRow);
                        setTableData([...tableData, newRow]);
                        console.log(newRow, 'new');
                        setTimeout(() => resolve(), 500)
                    }),
                    onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                        const updatedData = [...tableData]
                        console.log('newRow', newRow);
                        handleStudentUpdate(newRow, newRow._id)
                        updatedData[oldRow.tableData.id] = newRow
                        setTableData(updatedData)
                        setTimeout(() => resolve(), 500)
                    }),
                    onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                        const updatedData = [...tableData]
                        console.log('selectedRow', selectedRow)
                        updatedData.splice(selectedRow.tableData.id, 1)
                        removeContact(selectedRow)
                        // removeStudent(selectedRow._id)
                        setTableData(updatedData)
                        setTimeout(() => resolve(), 1000)

                    })
                }}
                actions={[
                    {
                        icon: () => <DeleteIcon />,
                        tooltip: "Delete Selected Data",
                        onClick: (e, data) => handleBulkDelete(data),
                        // isFreeAction:true
                    }
                ]}
                onSelectionChange={(selectedRows) => setSelectedRows(selectedRows)}
                options={{
                    sorting: true, search: true,
                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                    filtering: true, paging: true, pageSizeOptions: [2, 5, 10, 20, 25, 50, 100], pageSize: 5,
                    paginationType: "stepped", showFirstLastPageButtons: false, paginationPosition: "both", exportButton: true,
                    exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: true,
                    showSelectAllCheckbox: true, showTextRowsSelected: false, selectionProps: rowData => ({
                        disabled: rowData.age == null,
                        // color:"primary"
                    }),
                    grouping: false, columnsButton: false,
                    rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
                    headerStyle: {
                        background: "#198754", color: "#fff",
                    }
                }}
                title="Contacts"
                icons={{ Add: () => <AddIcon /> }} />
        </>
    );
}

export default ContactTable;