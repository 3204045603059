import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import './UpdateTemplate.css';
import Grid from '@mui/material/Grid';
import useTemplates from '../../hooks/useTemplates';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Paper, TextareaAutosize, TextField } from '@mui/material';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
const UpdateTemplate = () => {
    const { templates, loading } = useTemplates();
    const id = useParams().id;
    const currentTemplate = templates?.find(t => t._id === id);
    const [head, setHead] = useState('header text');
    const [templateType, setTemplateType] = useState('raw');
    const [subject, setSubject] = useState(currentTemplate?.subject || 'Email Subject!!');
    const [hyperLink, setHyperLink] = useState(currentTemplate?.hyperLink || '');
    // const [buttonTitle, setButtonTitle] = useState('Button Title');
    const [button_title, setButton_title] = useState(currentTemplate?.button_title || 'Visit Us');
    const [footer, setFooter] = useState(currentTemplate?.footer || 'Regards');
    const [body, setBody] = useState(currentTemplate?.body || 'Email Body Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus excepturi doloremque et explicabo animi cum pariatur inventore illum repudiandae nihil.');
    const [file, setFile] = useState(currentTemplate?.file);
    const [success, setSuccess] = useState(false);

    console.log(id)
    const { register, handleSubmit, reset } = useForm();

    console.log('currentTemplate', currentTemplate)
    console.log('currentTemplate file', file);
    const onSubmit = data => {
        console.log('id::::', id)
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/templates/${id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0 || data.modifiedCount > 0) {
                    alert('Template updated Successful');
                }
                else {
                    console.log('Already exist')
                }
            });
    }
    const handleBasicSubmit = e => {
        console.log('submitted');

        e.preventDefault();
        // if (!file) {
        //     return;
        // }
        console.log('submitted')
        const formData = new FormData();
        formData.append('file', file);
        formData.append('head', head);
        formData.append('body', body);
        formData.append('footer', footer);
        formData.append('subject', subject);
        formData.append('hyperLink', hyperLink);
        formData.append('button_title', button_title);
        // console.log('file', file);
        if (file) {
            for (let i = 0; i < file.length; i++) {
                formData.append(`file${i}`, file[i])
            }
        }
        else {

        }
        formData.append('templateType', templateType);
        console.log('subject', subject);
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/templates/${id}`, {
            method: 'PUT',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                if (data.upsertedCount > 0 || data.modifiedCount > 0) {
                    alert('Template updated Successful');
                }
                else {
                    alert('Template Update Failed');
                }
            })
            .catch(err => console.log(err))
        console.log('formData2', formData.entries());
    }
    // updated template result: {
    //     acknowledged: true,
    //     modifiedCount: 1,
    //     upsertedId: null,
    //     upsertedCount: 0,
    //     matchedCount: 1
    //   }
    let navigate = useNavigate();
    const changeRoute = () => {
        navigate('/createTemplate');
    }
    const goToTemplates = () => {
        navigate('/templates');
    }
    return (
        <Container>
            <Row>
                <Col xs={12} md={12}>
                    <Paper elevation={2} sx={{ p: 1, my: 2 }}>
                        <Row className="m-2">
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col md={12}>
                                        <h2>Update TEMPLATE {' '} <DriveFileRenameOutlineIcon /></h2>
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={12} md={6} className="p-0 d-flex justify-content-end align-items-center">
                                <button onClick={changeRoute} className="btn btn-success btn-small"><DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon> CREATE NEW TEMPLATE</button>
                                <button onClick={goToTemplates} className="btn btn-success mx-1"> All TEMPLATES <Badge badgeContent={templates?.length} color="error">
                                    <NewspaperIcon color="action" />
                                </Badge></button>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
            {currentTemplate ? <div>
                {
                    currentTemplate?.templateType === 'raw' ? <Row>
                        <Col xs={12} md={6}>
                            <div className="template-container">
                                <h6> <u>Subject:</u>  {currentTemplate?.subject}</h6>
                                <Paper elevation={1} sx={{ p: 1, mt: 1 }}>
                                    <p>{currentTemplate?.head || head}</p>
                                    <div className="email-body">
                                        <p>{currentTemplate?.body || body}</p>
                                    </div>
                                    <p><a href={hyperLink}>{button_title || 'Click here to visit'}</a></p>
                                    <p>{currentTemplate?.footer || footer}</p>
                                    <p></p>
                                </Paper>

                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="template-container basic-template">
                                <h2 className="text-center py-1">Provide the data</h2>
                                <form onSubmit={handleBasicSubmit}>
                                    <TextField
                                        sx={{ width: '100%', my: 1 }}
                                        id="outlined-basic"
                                        label="Email Subject"
                                        defaultValue={currentTemplate?.subject || subject}
                                        variant="outlined"
                                        onChange={e => setSubject(e.target.value)}
                                    />

                                    <TextField
                                        sx={{ width: '100%', my: 1 }}
                                        id="outlined-basic"
                                        label="Email Hearder"
                                        defaultValue={currentTemplate?.head || head}
                                        variant="outlined"
                                        onChange={e => setHead(e.target.value)}
                                    />
                                    <label>Email Body:</label>

                                    <TextareaAutosize
                                        sx={{ width: '100%', my: 1 }}
                                        label="Email Body"
                                        defaultValue={currentTemplate?.body || body}
                                        aria-label="Email Body"
                                        minRows={3}
                                        // placeholder="Email Body"
                                        style={{ width: "100%", border: "0.5px solid #909090" }}
                                        required
                                        onChange={e => setBody(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ width: '100%', my: 1 }}
                                        id="outlined-basic"
                                        label="Hyper Link"
                                        required
                                        defaultValue={currentTemplate?.hyperLink || hyperLink}
                                        variant="outlined"
                                        onChange={e => setHyperLink(e.target.value)}
                                    />
                                    <TextField
                                        sx={{ width: '100%', my: 1 }}
                                        id="outlined-basic"
                                        label="Hyper Link Text"
                                        required
                                        defaultValue={currentTemplate?.button_title || button_title}
                                        variant="outlined"
                                        onChange={e => setButton_title(e.target.value)}
                                    />

                                    <TextField
                                        sx={{ width: '100%', my: 1 }}
                                        id="outlined-basic"
                                        label="Email Footer"
                                        required
                                        defaultValue={currentTemplate?.footer || footer}
                                        variant="outlined"
                                        onChange={e => setFooter(e.target.value)}
                                    />
                                    <label>Select Attachment:</label>
                                    <input
                                        style={{ width: "100%", border: "0.5px solid #909090" }}
                                        type="file"
                                        multiple 
                                        accept="*"
                                                        // variant="contained"
                                        color="success"
                                        onChange={(e) => {
                                            setFile(e.target.files);
                                            // setFile(e.target.files[0]);
                                        }}
                                    />
                                    <Button variant="contained" type="submit" sx={{ width: '100%', mt: 2 }} style={{ width: "100%", background: "#198754" }}>
                                        Update Template
                                    </Button>
                                </form>
                                {success && <p style={{ color: 'green' }}>{success}</p>}
                            </div>

                        </Col>
                    </Row> :

            <Row>
                <Col xs={12} md={6}>

                        <div className="template-container">

                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>
                                <tr>
                                    <td valign="top" class="bg_white" style={{ padding: "1em 2.5em 0 2.5em" }}>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td class="logo">
                                                    {/* <h1><a href="#">bdemr</a></h1> */}
                                                    <h1><a href={currentTemplate?.button_link}>{currentTemplate?.Company_name}</a></h1>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="middle" class="hero bg_white" style={{ padding: "2em 0 4em 0" }}>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td style={{ padding: "0 2.5em", textAlign: "center", paddingBottom: "3em" }}>
                                                    <div class="text">
                                                        <h2>{currentTemplate?.promotion_title}</h2>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="text-author">
                                                        <img src={currentTemplate?.prodcut_img} alt="" style={{ width: "50%", maxWidth: "600px", height: "auto", margin: "auto", display: "block" }} />
                                                        <h3 class="name" style={{ textAlign: "center" }}>{currentTemplate?.promotion_subtitle}</h3>
                                                        <span class="position">{currentTemplate?.promotion_details}</span>
                                                        <p><a href={currentTemplate?.button_link} class="btn btnn-primary">{currentTemplate?.button_title}</a></p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>
                                <tr>
                                    <td valign="middle" class="bg_light footer email-section">
                                        <table>
                                            <tr>
                                                <td valign="top" width="34%" style={{ paddingTop: "20px" }}>
                                                    <table role="presentation" width="100%">
                                                        <tr>
                                                            <td style={{ textAlign: "left", paddingRight: "10px" }}>
                                                                <h3 class="heading">SUPPORT | VIEW ONLINE</h3>
                                                                <p>You received this transactional email because it contains information about your relationship with BDEMR.</p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td valign="top" width="33.333%" style={{ paddingTop: "20px" }}>
                                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                        <tr>
                                                            <td style={{ textAlign: "left", paddingLeft: "5px", paddingRight: "5px" }}>
                                                                <h3 class="heading">Contact Info</h3>
                                                                <ul>
                                                                    <li><span class="text">8505 Wildwood Pl, Surrey, BC V4N 5C5, Canada</span></li>
                                                                    <li><span class="text">+2 392 3929 210</span>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg_light" style={{ textAlign: "center" }}>
                                        <p>No longer want to receive these email? You can <a href={currentTemplate?.button_link}>Unsubscribe here</a></p>
                                    </td>
                                </tr>
                            </table>
                                </div>
                </Col>
                <Col xs={12} md={6}>
                    <div class="template-container">
                        <div className="add-service">
                            <h4 className="text-center py-1">Provide the data</h4>
                            <form onSubmit={handleSubmit(onSubmit)} className="pb-5">
                                <label>Template Name</label>
                                <input {...register("template_name", { maxLength: 300 })} defaultValue={currentTemplate?.template_name} />
                                <label>Company name:</label>
                                <input {...register("Company_name", { maxLength: 300 })} defaultValue={currentTemplate?.Company_name} />
                                <label>promotion title:</label>
                                <input {...register("promotion_title", { maxLength: 300 })} defaultValue={currentTemplate?.promotion_title} />
                                <label>Promotional Image URL</label>
                                <input type="text" {...register("prodcut_img")} defaultValue={currentTemplate?.prodcut_img} />
                                <label>About the Product:</label>
                                <input {...register("promotion_subtitle", { maxLength: 300 })} defaultValue={currentTemplate?.promotion_subtitle} />
                                <label>Promotion Details:</label>
                                <textarea type="text" {...register("promotion_details")} defaultValue={currentTemplate?.promotion_details} />
                                <label>Button title:</label>
                                <input type="text" {...register("button_title")} placeholder="Button title" defaultValue={currentTemplate?.button_title} />
                                <label>Button link:</label>
                                <input type="text" {...register("button_link")} defaultValue={currentTemplate?.button_link} />
                                <Button type="submit" variant="contained" color="success" size="large">Update</Button>
                                {/* <input type="submit" /> */}
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
                }
            </div> :
                <div className="text-center my-5 private-spinner py-5">
                    <Spinner variant="success" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <h6>Please Wait...</h6>
                </div>
            }

        </Container >
    );
};
export default UpdateTemplate;