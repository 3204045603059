import { useEffect, useState } from "react";
import useAuth from "./useAuth";
// import Swal from "sweetalert2";

const useTemplates = () => {
  const [templates, setTemplates] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/templates?email=${user.email}`)
      .then((res) => res.json())
      .then((data) => {
        setTemplates(data);
        // console.log('templates: from useTemplates:', data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);
  // useEffect(() => {
  //   fetch("https://rocky-reef-85960.herokuapp.com/products") // Demo Link
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts(data);
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     });
  // }, []);
  return { templates, loading, setLoading };
};

export default useTemplates;
