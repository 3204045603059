import React, { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useContacts = () => {
    const [contacts, setContacts] = useState([]);
    // const [contactsCount, setContactsCount] = useState([]);
    const [contactEmails, setContactEmailss] = useState([]);
    const [deletedContacts, setDeletedContacts] = useState([]);
    const { user, reload } = useAuth();
    console.log(reload, 'reload')

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts/?email=${user.email}`)
            .then(res => res.json())
            .then(data => {
                let contactEmails = data[0]?.emails.filter(u => u.isDeleted !== true);
                let deletedContactEmails = data[0]?.emails.filter(u => u.isDeleted === true);
                setContacts(data)
                setContactEmailss(contactEmails)
                setDeletedContacts(deletedContactEmails)
            })
            .catch((err) => console.log(err));
    }, [reload]);
    console.log(contacts);
    const contactsCount = contactEmails?.length;
    return { contacts, contactEmails, contactsCount, deletedContacts };
};

export default useContacts;