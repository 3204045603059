import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './../../Templates/Template/Demo-Template/template.css';
import './templateForm.css'
import './../../Templates/Template/templateForm.css';
import useTemplates from '../../../hooks/useTemplates';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Grid from '@mui/material/Grid';
import AllTemplates from '../../AllTemplates/AllTemplates';
import { Badge, Paper, Typography } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useNavigate } from 'react-router-dom';

const Template = () => {
  const { templates, loading } = useTemplates();
  const [refresh, setRefresh] = useState();
  // const tem
  console.log('template data', templates, loading);
  // if (!loading) {
  //   const { template_name, Company_name, promotion_title, promotion_subtitle, promotion_details, prodcut_img, button_title
  //     , button_link
  //   } = templates[0] || {};
  // }
  let navigate = useNavigate();
  const changeRoute = () => {
    navigate('/createTemplate');
  }
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = data => {
    axios.post(`${process.env.REACT_APP_SERVERHOST}:7002/templates`, data)
      .then(res => {
        if (res.data.insertedId) {
          alert('added successfully');
          reset();
        }
      })
      .catch(err => console.log(err))
  }

    return (
      <Container>
        <Paper elevation={2} sx={{ p: 1, my: 2 }}>
          <Row className="m-2">
            <Col xs={12} md={4}>
              <Row>
                <Col md={6}>
                  <h2>Templates</h2>
                </Col>
                <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                  <Badge badgeContent={templates?.length} color="success">
                    <NewspaperIcon color="action" />
                  </Badge>
                </Col>
              </Row>

            </Col>
            <Col xs={12} md={8} className="p-0 d-flex justify-content-end align-items-center">
              <button className="btn btn-success" onClick={changeRoute}><DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon> CREATE TEMPLATE</button>
            </Col>
          </Row>
        </Paper>
        <Paper elevation={0} sx={{ p: 1, my: 2 }} style={{ display: "block" }}>
          {/* <Container> */}
          <AllTemplates
            setRefresh={setRefresh}
            templates={templates}
          ></AllTemplates>
          {/* </Container> */}
        </Paper>
        {/* <h2 className="text-center pb-3">DEMO TEMPLATE</h2> */}
      </Container>

    );
};

export default Template;