import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Badge, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Spinner, Table, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useEmails from '../../hooks/useEmails';
import useInboxEmails from '../../hooks/useInboxEmails';
import useSettings from '../../hooks/useSettings';
import useTemplates from '../../hooks/useTemplates';
import useThreads from '../../hooks/useThreads';
import mailsLoading from '../../visuals/mail-download.gif';
import Inbox_SingleEmail from '../Inbox_SingleEmail/Inbox_SingleEmail';
import useDataInfo from '../../database/useDataInfo';
const Inbox = () => {
    const { emails, emailsCount } = useEmails();
    const [count, setCount] = useState(0);
    const [mails, setMails] = useState();
    // const { inbox, inboxCount } = useInboxEmails();
    // const { inbox, tempMails } = useState();
    const [isModalON, setIsModalON] = useState(false);
    const { settings, delay, setDelay, isAutoRespond, defaultTemplate, setDefaultTemplate } = useSettings();
    const { addSetting } = useDataInfo();
    const { templates, loading } = useTemplates();
    // const [delay, setDelay] = useState(60);
    const [emailData, setEmailData] = useState({});
    console.log(settings[0]?.delay, 'settings', delay);
    // const { instantMails } = useEmails('INBOX');
    // console.log('Instant Mails: ', instantMails);
    // // setMails(instantMails);
    const [show, setShow] = useState(false);
    const { threads } = useThreads();
    useEffect(() => {
        // setDelay(delayTime);
        // console.log('delay time: ', delay);
        const interval = setInterval(() => {
            // if (isModalON === false) {
            // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails/UNREAD`)
            fetch(`${process.env.REACT_APP_SERVERHOST}:7002/getImapResponse`)
                .then((res) => res.json())
                .then((data) => {
                    setMails(data);
                    console.log('data loaded', data);
                })
                .catch((err) => {
                    console.log('Error Found: ', err)
                });

            // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails/INBOX`)
            //     .then((res) => res.json())
            //     .then((data) => {
            //         if (data.length !== mails) setMails(data);
            //         // addMailsToDb(data.length);
            //         console.log('mails api hitteddd');
            //         if (mails.length !== data.length) console.log('mails', data)
            //     })
            //     .catch((err) => {
            //         console.log('Error Found: ', err)
            //     });
            // }
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    // const inboxMails = mails?.filter(item => !item.labelIds.includes('SENT'));
    const inboxMails = mails;
    // inboxMails.map(item => addEmailsToDB(item));
    const [value, setValue] = useState();

    // const intdelay = parseInt(settings[0]?.delay);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    // addToDb(tempMails);
    console.log('inboxMails: ', inboxMails?.length);
    const tempMails = useInboxEmails();
    console.log('tempMails 1: ', tempMails.length);
    // if (tempMails?.length !== inboxMails.length) {
    // mails.map(item => {
    //     if (!item.labelIds.includes('SENT')) {
    //         tempMails.push(item);

    //     }
    //     console.log('Temp Mails Updated')
    // });
    // inboxMails.map(item => addEmailsToDB(item));
    // }
    // console.log('tempMails 2: ', tempMails.length);
    // addMailsToDb(tempMails.length);
    inboxMails?.sort((dateA, dateB) => dateB.internalDate - dateA.internalDate);
    // console.log('tempMails: ', tempMails);
    // let tempMails2 = [];
    // // console.log('tempMails: ', tempMails);
    // mails.map(item => {
    //     if (item.labelIds.includes('SENT')) {
    //         tempMails2.push(item);
    //     }
    // });
    // console.log('tempMails2: ', tempMails2)
    // console.log('tempMails after state: ', tempMails);

    // console.log('Inbox mails:', inboxMails);
    // const mailData = new Buffer(mails.parts[0].body.data, 'base64').toString();
    const handleEmailDetails = (data) => {
        setEmailData(data)
    }
    const hnadleSetDelay = (n) => {
        // setDelay(n)
    }

    const refresh = () => {
        // re-renders the component
        setValue({});
    }
    // mails.sort((dateA, dateB) => dateB.internalDate - dateA.internalDate);
    const [modalShow, setModalShow] = React.useState(false);
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {
        setDelay(data.delay)
        console.log('delay data', data);
        setModalShow(false);
        setIsModalON(false);
        // data['_id'] = settings[0]._id;
        addSetting(data, settings[0]?._id);
        console.log(data);
        toggleDrawer('bottom', false)

    };
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <small>Set delay time to Auto respond</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                    <h6>Delay Time: {delay} seconds</h6>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="number" {...register("delay", { min: 0, max: 1000 })} defaultValue={delay} />
                        <input type="submit" />
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button type="submit" onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        );
    }
    const handleAutoReply = (delay) => {

    }
    // MUI Drawer 
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const anchor = 'bottom'
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
                <ListItem button key={'SET DELAY TIME'}>
                    <ListItemIcon>
                        {1 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    {/* <ListItemText primary={'SET DELAY TIME'} /> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="number" {...register("delay", { min: 0, max: 1000 })} defaultValue={delay} />
                        <input type="submit" />
                    </form>
                </ListItem>
                {/* ))} */}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    return (
        <div className="container mt-3">
            <p>Mails: {mails?.length}</p>
            <Paper elevation={2} sx={{ py: 1, mt: 1 }}>
            <Row className="m-2">
                    <Col xs={6} md={2}>
                        <Row>
                            <Col md={9} className="sub-title text-left">
                                <h2>Inbox {' '}<Badge badgeContent={inboxMails?.length} color="success" className="ms-2">
                                    <MailIcon color="action" />
                                </Badge></h2>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={6} md={2} className="p-0">
                </Col>
                <Col xs={12} md={8} className="p-0 d-flex justify-content-end align-items-center">

                    {isAutoRespond ?
                        <>
                            <ScheduleSendIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Auto Response" />
                            <Badge pill bg="success" style={{ marginRight: '20px' }} >{delay} sec</Badge>
                        </>
                        :
                        <CancelScheduleSendIcon style={{ fontSize: 24, marginRight: '20px', color: "#198754" }} title="Auto Response" />

                    }
                        <React.Fragment key={anchor}>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                        </React.Fragment>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </Col>
            </Row>
            </Paper>

            <Paper className="bg-white table-style inbox-paper" sx={{ width: '100%', p: 1, mt: 1 }}>
                {inboxMails?.length && settings?.length && templates ? <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Sender</th>
                            <th colSpan="3">Subject</th>
                            <th>Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className="inbox-row">
                        {
                            inboxMails?.length ? inboxMails?.map(mail =>
                                // eslint-disable-next-line react/jsx-pascal-case
                                <Inbox_SingleEmail
                                    key={mail.id}
                                    message={mail}
                                    handleEmailDetails={handleEmailDetails}
                                    isAutoRespond={isAutoRespond}
                                    setShow={setShow}
                                    threads={threads}
                                    settings={settings}
                                    delay={delay}
                                    defaultTemplate={defaultTemplate}
                                    setDefaultTemplate={setDefaultTemplate}
                                    templates={templates}
                                ></Inbox_SingleEmail>) : <div className="text-center my-5 private-spinner py-5">
                                <Spinner variant="success" animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                <h6>Loading...</h6>
                            </div>
                        }
                    </tbody>
                </Table> : <div className="mail-loading-gif py-0"><img src={mailsLoading} alt="loading" className="" />
                    <h6>Loading...</h6>
                </div>}
            </Paper>
            <Toast onClose={() => setShow(false)} bg="success" show={show} delay={3000} autohide style={{ position: 'absolute', top: '85%', left: '10%' }}>
                <Toast.Header>
                    <strong className="me-auto">SENT!!</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>Auto Responded successfully!</Toast.Body>
            </Toast>
        </div>
    );
};

export default Inbox;