import React from "react";

function TextItem({ label, value }) {
  return (
    <h6>
      {label}: <span className="appCapsole"> {value}</span>
    </h6>
  );
}

export default TextItem;
