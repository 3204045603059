import BackupIcon from '@mui/icons-material/Backup';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InboxIcon from '@mui/icons-material/Inbox';
import { Badge, Container, Paper } from '@mui/material';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useContacts from '../../hooks/useContacts';
import useEmails from '../../hooks/useEmails';
import useTemplates from '../../hooks/useTemplates';

const Dashboard = () => {
  // inbox
  const { mails } = useEmails('INBOX');
  const inboxMails = mails?.filter(item => !item.labelIds.includes('SENT'));
  const { totalInboxMailCount } = useAuth()
  // sent
  // const { mails } = useEmails('SENT');
  let tempMails = [];
  mails?.map(item => {
    if (item.labelIds.includes('SENT')) {
        tempMails.push(item);
        console.log('SENT message: ', item);
    } 
}
);

  // contacts

  const { contactsCount } = useContacts();
  const { templatesCount } = useTemplates();
  let broadcastCount = 1;
    
  return (
      <>
      <div className="content container border-3 p-3">
      <Paper>
              <Row>
                <Col xs={6} md={6} className="p-0">
                  <Container className="sub-title text-left">
                    <Row>
                      <Col md={8}>Dashboard&nbsp;
                        <Badge color="success">
                          <DashboardCustomizeIcon color="action" />
                        </Badge>
                      </Col>
                      <Col xs={12} md={4} className="p-0 d-flex justify-content-end align-items-center">
                      </Col>
                    </Row>
                  </Container>
                </Col>   
              </Row>
            </Paper>

        {/* inbox */}
        <Row style={{padding: '8px 10px'}}>
          <Col lg="3" md="6" sm="6" style={{padding: '8px'}}>
            <Card className="card-stats">
              <Card.Body>
                <Row as={Link} to="/inbox">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                    {/* <HiOutlineLibrary className="text-3xl" /> */}
                    <InboxIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7" >
                    <div className="numbers">
                      <p className="card-category">Inbox</p>
                      <Card.Title tag="p">{inboxMails?.length || totalInboxMailCount}</Card.Title>
                      <p />
                    </div>
                  </Col>
                  {/* <Row>
                    <Col md={9} className="sub-title text-left">
                      <h2>Inbox</h2>
                        </Col>
                          <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                          <Badge badgeContent={mails?.length} color="success">
                            <MailIcon color="action" />
                          </Badge>
                    </Col>
                  </Row> */}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* sent */}
          <Col lg="3" md="6" sm="6" style={{padding: '8px'}}>
            <Card className="card-stats">
              <Card.Body>
                <Row as={Link} to="/">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                    {/* <HiOutlineLibrary className="text-3xl" /> */}
                    <InboxIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Sent Mails</p>
                      <Card.Title tag="p">{tempMails?.length}</Card.Title>
                      <p />
                    </div>
                  </Col>
                  {/* <Row className="mx-0">
                    <Col Col md={9} className="sub-title text-left">
                      <h2>Sent</h2>
                    </Col>
                    <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                      <Badge badgeContent={0} color="success">
                                    <MailIcon color="action" />
                      </Badge>
                    </Col>
                  </Row> */}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* contacts */}
          <Col lg="3" md="6" sm="6" style={{padding: '8px'}}>
            <Card className="card-stats">
              <Card.Body>
                <Row as={Link} to="/contacts">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <ChromeReaderModeIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Contacts</p>
                        <Card.Title tag="p">{contactsCount}</Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mx-0">
                    <Col Col md={9} className="sub-title text-left">
                      <h2>Contacts</h2>
                    </Col>
                    <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                     <Badge badgeContent={contactsCount} color="success">
                       <MailIcon color="action" />
                     </Badge>
                    </Col>
                  </Row> */}
              </Card.Body>
            </Card>
          </Col>

          {/* broadcast */}
          <Col lg="3" md="6" sm="6" style={{padding: '8px'}}>
            <Card className="card-stats">
              <Card.Body>
                <Row as={Link} to="/templates">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <NewspaperIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Templates</p>
                      <Card.Title tag="p">{templatesCount}</Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" style={{ padding: '8px' }}>
            <Card className="card-stats">
              {/* <Card.Body>
                <Row as={Link} to="/contacts">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <ChromeReaderModeIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                </Row>
                <Row className="mx-0">
                    <Col Col md={9} className="sub-title text-left">
                      <h2>Broadcast</h2>
                    </Col>
                    <Col md={3} className="p-0 d-flex justify-content-end align-items-center">
                     <Badge badgeContent={0} color="success">
                       <MailIcon color="action" />
                     </Badge>
                    </Col>
                  </Row>
              </Card.Body> */}
              <Card.Body>
                <Row as={Link} to="/broadcast">
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <BackupIcon style={{ fontSize: 70 }} />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Broadcast</p>
                      <Card.Title tag="p">{'Go to BroadCast'}</Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* 2nd Part */}
        {/* <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title tag="h5">Users Behavior</Card.Title>
                <p className="card-category">24 Hours Performance</p>
              </Card.Header>
              <Card.Body>
                
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        
        <Row style={{padding: '15px'}}>
          {/* <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title tag="h5">Email Statistics</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body style={{ height: "266px" }}>
                
              </Card.Body>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Opened{" "}
                  <i className="fa fa-circle text-warning" /> Read{" "}
                  <i className="fa fa-circle text-danger" /> Deleted{" "}
                  <i className="fa fa-circle text-gray" /> Unopened
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Number of emails sent
                </div>
              </CardFooter>
            </Card>
          </Col> */}
          {/* <Col md="8">
            <Card className="card-chart">
              <Card.Header>
                <Card.Title tag="h5">Patient Statistics</Card.Title>
                <p className="card-category">Line Chart with Points</p>
              </Card.Header>
              <Card.Body>
                
              </Card.Body>
               <CardFooter>
                <div className="chart-legend">
                  <i className="fa fa-circle text-info" /> Corona{" "}
                  <i className="fa fa-circle text-warning" /> Corona Omicron
                </div>
                <hr />
                <div className="card-stats">
                  <i className="fa fa-check" /> Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
    );
};

export default Dashboard;