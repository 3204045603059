// import { Button } from 'bootstrap';
// import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Button, Paper } from "@mui/material";
import React from "react";
import { Badge, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import useContacts from "../../hooks/useContacts";
import SingleEmail from "../Contacts/SingleEmail/SingleEmail";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import useSettings from "../../hooks/useSettings";
import useTemplates from "../../hooks/useTemplates";
import GetHtmlTemplate from "../../hooks/GetHtmlTemplate";
import SingleTemplate from "../SingleTemplate/SingleTemplate";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Broadcast = () => {
  useDocumentTitle("Broadcast 📡| BDEMR Email Autoresponder");
  const { contacts, contactEmails, contactsCount } = useContacts();
  const { settings } = useSettings();
  const { templates } = useTemplates();
  const { user } = useAuth();
  const broadCastTemplate =
    templates?.find((t) => t._id === settings[0]?.broadCastTemplate) ||
    (templates && templates[0]);
  console.log("broadCastTemplate: ", broadCastTemplate);
  console.log("contactEmails: ", contactEmails);
  let defaultReply = {};
  if (templates?.length) {
    defaultReply = GetHtmlTemplate(broadCastTemplate);
  }
  //year, month 0-11, date, hour, min (can add ,sec,msec)
  const eta_ms = new Date(2021, 11, 12, 18, 0).getTime() - Date.now();
  const timeout = setTimeout(function () {
    // alert("it's time");
    console.log(Date.now());
  }, eta_ms);
  let navigate = useNavigate();
  const changeRoute = () => {
    navigate("/contacts");
  };
  // console.log(contacts[0].emails, 'from broadcast')
  const handleSendToAll = () => {
    if (window.confirm("Are yoy sure?!")) {
      // contacts.map(contact => {
      //     if (contact.email.includes('noreply') && contact.email.includes('no-reply')) {
      //         console.log('Reply is not allowed');
      //     }
      //     else {
      //         const data = {
      //             html: defaultReply,
      //         }
      //         const url = `http://${process.env.REACT_APP_SERVERHOST || 'localhost'}:7002/respond/${contact.email}`;
      //         axios.post(url, data)
      //             .then(res => {
      //                 if (res.data.insertedId) {
      //                     alert('added successfully');
      //                 }
      //             })
      //             .then(res => res.json())
      //             .then(data => {
      //                 if (data) {
      //                     console.log('Sent Successful');
      //                 }
      //                 else {
      //                     alert('failed')
      //                 }
      //             })
      //             .catch(err => console.log(err));
      //     }
      // })
      console.log("contacts", contacts);
      const data = {
        html: defaultReply,
        subject: broadCastTemplate.subject,
        contacts: contactEmails,
        file: broadCastTemplate.file,
      };
      console.log(user.email, "user.email");
      const url = `${process.env.REACT_APP_SERVERHOST}:7002/broadcast?email=${user.email}`;
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.insertedId) {
            alert("added successfully");
          }
        })
        .then((res) => {
          //   res.json();
          console.log(res);
        })
        .then((data) => {
          // if (data?.sentCount) {
          console.log("Sent Successful:", data);
          if (data?.messageId) {
            console.log("Sent Successful:", data);
            alert("Sent Successful:", data?.messageId);
          } else {
            // alert("failed");
          }
        })
        .catch((err) => {
          console.log(err);
          // alert('Something went wrong, please try again')
        });

      // contacts.map((contact, index) => {
      //    contact.emails.map((contactData)=>{
      //     if (contactData.email.includes('noreply') && contactData.email.includes('no-reply')) {
      //         console.log('Reply is not allowed');
      //     }
      //     else {
      //         setTimeout(function () {
      //             const data = {
      //                 html: defaultReply,
      //                 subject: broadCastTemplate.subject,
      //                 contacts: contacts,
      //                 file: broadCastTemplate.file
      //             }
      //             const url = `http://${process.env.REACT_APP_SERVERHOST || 'localhost'}:7002/broadcast`;
      //             axios.post(url, data)
      //                 .then(res => {
      //                     if (res.data.insertedId) {
      //                         alert('added successfully');
      //                     }
      //                 })
      //                 .then(res => res.json())
      //                 .then(data => {
      //                     if (data) {
      //                         console.log('Sent Successful');
      //                     }
      //                     else {
      //                         alert('failed')
      //                     }
      //                 })
      //                 .catch(err => console.log(err));
      //         }, 40000 * index);

      //     }
      // })})
    } else {
    }
  };
  const handleTestSend = () => {
    if (window.confirm("Are yoy sure?!")) {
      console.log("contacts", contacts);
      const data = {
        html: defaultReply,
        subject: broadCastTemplate.subject,
        contacts: [{ isDeleted: false, email: "sabuj.bdemr@gmail.com" }],
        file: broadCastTemplate.file,
      };
      console.log(user.email, "user.email");
      const url = `${process.env.REACT_APP_SERVERHOST}:7002/broadcast?email=${user.email}`;
      axios
        .post(url, data)
        .then((res) => {
          //   res.json();
          console.log(res);
          if(res.data.error){
            alert(res.data.error)
          }
        })
        .then((res) => {
          if (res.data.insertedId) {
            alert("added successfully");
          }
        })
        .then((data) => {
          // if (data?.sentCount) {
          console.log("Sent Successful:", data);
          if (data?.messageId) {
            console.log("Sent Successful:", data);
            alert("Sent Successful:", data?.messageId);
          } else {
            // alert("failed");
          }
        })
        .catch((err) => {
          console.log(err);
          // alert('Something went wrong, please try again')
        });
    } else {
    }
  };
  return (
    <Container>
      <Paper sx={{ width: "100%", p: 1, my: 1 }}>
        <Row>
          <Col xs={12} md={6} className="p-0">
            <Container className="sub-title text-left">
              <Row>
                <Col md={6}>
                  <h2>
                    Broadcast <PodcastsIcon />
                  </h2>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Paper>
      {templates?.length ? (
        <Card className="text-center mb-3">
          {/* <Card.Header>
                        <Paper elevation={2} sx={{}}>
                            <Row className="m-2">
                                <Col xs={12} md={12} className="p-0 d-flex justify-content-center align-items-center">
                                    Broadcast Email
                                </Col>
                            </Row>
                        </Paper>
                    </Card.Header> */}
          <Card.Body>
            <Card.Title>
              List: All Contacts{" "}
              <Badge pill bg="secondary">
                {contactEmails?.length}
              </Badge>
            </Card.Title>
            <Paper elevation={0} sx={{ p: 1, my: 2 }}>
              <Row className="m-2">
                <Col
                  xs={12}
                  md={12}
                  className="p-0 d-flex justify-content-center align-items-center"
                >
                  <p>
                    {contacts?.length > 0 ? (
                      // contactEmails.map((contact) => (
                      //   <SingleEmail
                      //     key={contact._id}
                      //     email={contact.email}
                      //     // handleEmailDetails={handleEmailDetails}
                      //   ></SingleEmail>
                      // ))
                      <></>
                    ) : (
                      <Alert severity="error">
                        <AlertTitle>Not Contacts Found</AlertTitle>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={changeRoute}
                        >
                          {" "}
                          Add Contact Emails from Contacts
                        </Button>
                        {/* <strong>Try again!</strong> */}
                      </Alert>
                    )}
                  </p>
                  {/* <p>
                        {
                            contacts?.length > 0 && contacts?.map(contact =>{
                                return(
                                    contactEmails.map(contactData =>
                                    <SingleEmail
                                    key={contact._id}
                                    email={contactData.email}
                                // handleEmailDetails={handleEmailDetails}
                                ></SingleEmail>

                                    ));

                                }
                              )
                                        }
                    </p> */}
                </Col>
              </Row>
            </Paper>

            <div className="single-template-container">
              <SingleTemplate temp={broadCastTemplate}></SingleTemplate>
            </div>
            <Row className="m-2 mt-3">
              <Col
                xs={12}
                md={12}
                className="p-0 d-flex justify-content-center align-items-center"
              >
                {/* <p>Each mail will be sent at 15 sec interval</p> */}
              </Col>
              <Col
                xs={12}
                md={12}
                className="p-0 d-flex justify-content-center align-items-center"
              >
                <a href="/templates" className="btn btn-success mx-1">
                  Chnage Boradcast Template
                  <Badge badgeContent={templates?.length} color="error">
                    {/* <NewspaperIcon color="action" /> */}
                  </Badge>
                </a>
                {contacts?.length > 0 && (
                  <button
                    className="btn btn-success"
                    onClick={handleSendToAll}
                    title="15 seconds delay interval"
                  >
                    <SendIcon /> Send
                  </button>
                )}
              </Col>
              {/* <Col>
                <button
                  className="btn btn-success"
                  onClick={handleTestSend}
                  title="15 seconds delay interval"
                >
                  <SendIcon /> Test Send
                </button>
              </Col> */}
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <div className="text-center my-5 private-spinner py-5">
          <Spinner variant="success" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <h6>Loading...</h6>
        </div>
      )}
    </Container>
  );
};

export default Broadcast;