import React, { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useSettings = () => {
    const [settings, setSettings] = useState({});
    const [delay, setDelay] = useState();
    const [reply2, setReply2] = useState();
    const [reply3, setReply3] = useState();
    const [reply4, setReply4] = useState();
    const [reply5, setReply5] = useState();
    const [reply6, setReply6] = useState();
    const [reply7, setReply7] = useState();
    const [reply8, setReply8] = useState();
    const [reply9, setReply9] = useState();
    const [reply10, setReply10] = useState();
    const [defaultTemplate, setDefaultTemplate] = useState();
    const [isAutoRespond, setIsAutoRespond] = useState(false);
    const { user } = useAuth();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/settingInfo?email=${user?.email}`)
            .then(res => res.json())
            .then(data => {
                setSettings(data);
                let delayTime = parseInt(data[0]?.delay);
                setDelay(delayTime);
                // console.log(data[0].isAutoRespond);
                setIsAutoRespond(data[0].isAutoRespond);
                setDefaultTemplate(data[0].defaultTemplate);
            })
            .catch((err) => console.log(err));
    }, [delay, isAutoRespond]);
    // console.log(settings);
    return {
        settings, setSettings, delay, setDelay, isAutoRespond, setIsAutoRespond,
        defaultTemplate, setDefaultTemplate,
        reply3, setReply3,
        reply4, setReply4,
        reply2, setReply2,
        reply5, setReply5,
        reply6, setReply6,
        reply7, setReply7,
        reply8, setReply8,
        reply9, setReply9
    };
};
export default useSettings;