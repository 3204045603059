import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactDOM from "react-dom";
import './Login.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import googleIcon from '../../../visuals/google.png'
import { useLocation, useNavigate } from 'react-router-dom';
// import useAuth from "../../hooks/useAuth";
import useAuth from "../../../hooks/useAuth";
import { Alert, Box, FormControlLabel, FormGroup, LinearProgress, Paper, Stack, Switch, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import Skeleton from '@mui/material/Skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import LoadingButton from '@mui/lab/LoadingButton';
import mailsLoading from "../../../visuals/mail-download.gif";
const Login = () => {
  const [checked, setChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //   const eye = <FontAwesomeIcon icon={faEye} />;
  const [passwordShown, setPasswordShown] = useState(false);
  const eye = !passwordShown ? (
    <VisibilityIcon size="small" sx={{ fontSize: 16 }} />
  ) : (
    <VisibilityOffIcon size="small" sx={{ fontSize: 16 }} />
  );
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const {
    signInUsingGoogle,
    addUserTodatabase,
    setIsLoading,
    isLoading,
    loginUser,
    verifyUserLogin,
    registerWithEmail,
    error,
  } = useAuth();
  let errorMsg = error;
  if (errorMsg.includes("auth")) {
    console.log("yes");
    errorMsg = errorMsg.split("/")[1];
    errorMsg = errorMsg.split(")")[0];
  }
  const location = useLocation();
  const history = useNavigate();
  const redirect_uri = location.state?.from || "/home";
  const onLoginSubmit = (data) => {
    console.log(data);
    loginUser(data.email, data.password, redirect_uri, history);

    // if (!checked) {
    //     loginUser(data.email, data.password, redirect_uri, history);
    //     console.log('loginUser');
    // }
    // else {
    //     registerWithEmail(data.displayName, data.email, data.password, history);
    //     console.log('registerWithEmail');
    // }

    // addUserTodatabase(data.email, data.password, 'PUT')
    // const userInfo = verifyUserLogin(data.email, data.password);
    // console.log('userInfo', data)
  };
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const handleGoogleSignIn = () => {
    signInUsingGoogle(location, history);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <Paper elevation={2} className="bg-light m-5">
      <div>
        {isLoading && (
          <Stack sx={{ width: "100%", color: "grey.500" }} spacing={0}>
            <LinearProgress
              color="success"
              style={{ padding: "0", margin: "0" }}
            />
          </Stack>
        )}
      </div>
      <Box className="bg-light login-componenet pb-0 m-5 mt-1">
        {/* <Typography variant="h4" className="text-center pb-2">{checked ? 'Sign Up' : 'Login'} <LoginIcon /></Typography> */}
        <Typography variant="h4" className="text-center pb-2">
          {" "}
          Login
          <LoginIcon />
        </Typography>

        <form onSubmit={handleSubmit(onLoginSubmit)}>
          {/* {
        checked ?
            <div>
                <label>Name:</label>
                <input placeholder="Name" {...register("displayName", { required: true })} />
            </div>
            : ""
    } */}
          <label>Email:</label>
          <input
            placeholder="example@yourdomain"
            {...register("email", { required: true })}
          />
          {/* include validation with required or other standard HTML validation rules */}
          <label>Password:</label>
          {/* <input
            type="password"
            {...register("password", { required: true })}
            placeholder="password"
          /> */}
          <div className="pass-wrapper">
            <input
              className="pass-input"
              placeholder="Password"
              name="password"
              type={passwordShown ? "text" : "password"}
              {...register("password", { required: true })}
            />
            <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
          </div>
          {/* errors will return when field validation fails  */}
          {errors.exampleRequired && <p>This field is required</p>}
          <div className="d-grid gap-2">
            {setIsLoading ? (
              <Skeleton variant="rectangular" height={50} />
            ) : (
              <Button variant="light" size="sm" type="submit" className="">
                Login
              </Button>
            )}
            {/* <LoadingButton
            // onClick={handleClick}
            type="submit"
            loading={setIsLoading}
            loadingIndicator="Loading..."
            variant="outlined"
        >
            Fetch data
        </LoadingButton> */}
          </div>
          {/* <input type="submit" /> */}
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            // p: 1,
            // m: 1,
            // bgcolor: 'background.paper',
            // borderRadius: 1,
          }}
        >
          {/* <Typography>
        {
            checked ? "Already registered?" : "Don't have an account?"
        }
    </Typography> */}
          {/* <FormGroup >
        <FormControlLabel control={<Switch
            checked={checked}
            onChange={handleChange}
            color="success"
            inputProps={{ 'aria-label': 'controlled' }}
        />} label={
            checked ? "Already registered? Please Login" : "Don't have an account? Register"
        } />
    </FormGroup> */}
        </Box>

        {/* <p className="text-center">-- or --</p> */}
        <div className="other-login w-100">
          {/* <ButtonGroup aria-label="Basic example" className="w-100" disabled>
        <Button variant="outline-success">
            <img src={googleIcon} alt="" />
        </Button>
        <Button variant="success" onClick={handleGoogleSignIn} >Log in with Google </Button>
    </ButtonGroup> */}
        </div>
        {error && <Alert severity="error">{errorMsg}</Alert>}
      </Box>
    </Paper>
  );
};

export default Login;