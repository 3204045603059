import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import googleIcon from "../../visuals/google.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
// import useAuth from "../../../hooks/useAuth";
import {
  Container,
  Alert,
  Box,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertTitle,
  Fade,
  Modal,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import LoginIcon from "@mui/icons-material/Login";
import useAccounts from "../../hooks/useAccounts";
import TextItem from "../../reusables/TextItem";
import LoadingComponent from "../../reusables/LoadingComponent";
import ManageUser from "./ManageUser";
import useWallet from "../../hooks/useWallet";

const Account = () => {
  const [emailLogs, setEmailLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    signInUsingGoogle,
    addUserTodatabase,
    isLoading,
    isUploading,
    setIsLoading,
    loginUser,
    verifyUserLogin,
    registerWithEmail,
    error,
    setError,
    user,
    alertMessage,
    setAlertMessage,
  } = useAuth();
  let now = new Date();
  const today = now.toISOString().split("T")[0];
  const month = [now.getFullYear(), now.getMonth() + 1].join("-");
  console.log("today, month:", today, month);
  const { accounts, userAccount } = useAccounts(user.email);
  const [expanded, setExpanded] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [dailyLimit, setDailyLimit] = useState(5000);
  const [monthlyLimit, setMonthlyLimit] = useState(150000);
  // const [allUserWalletLogs, setAllUserWalletLogs] = useState([]);
  // const [myWallet, setMyWallet] = useState();
  const location = useLocation();
  const [sentToday, setSentToday] = useState(
    emailLogs?.dailyLogs?.[today] ? emailLogs?.dailyLogs[today]?.sent : 0
  );
  // console.log(
  //   "emailLogs?.monthlyLogs?.[month]:",
  //   emailLogs?.monthlyLogs?.[month]
  // );
  const [sentThisMonth, setSentThisMonth] = useState(
    emailLogs?.monthlyLogs?.[month] ? emailLogs?.monthlyLogs[month].sent : 0
  );
  // console.log("sentThisMonth:", sentThisMonth);
  const history = useNavigate();
  const redirect_uri = location.state?.from || "/home";
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  if (error || alertMessage) {
    setTimeout(() => {
      setError("");
      setAlertMessage("");
    }, 5000);
  }

  useEffect(() => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVERHOST}:7002/emailLogs?email=${user.email}`
    )
      .then((res) => res.json())
      .then((data) => {
        setEmailLogs(data[0]);
        // setSentToday(data[0]?.dailyLogs[today]?.sent);
        // setSentThisMonth(data[0]?.monthlyLogs[month]?.sent);
        console.log("EMAIL LOGS:", data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    console.log("userAccount?.role:", userAccount?.role);
    // if (userAccount?.role === "admin") {
    // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/wallet`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setAllUserWalletLogs(data);
    //     let myWalletData = data.find((item) => item.userEmail === user.email);
    //     console.log("allUserWalletLogs:", data);
    //     setMyWallet(myWalletData);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // }

    console.log("My Wallet:", myWallet);
  }, [userAccount]);
  const { allUserWalletLogs, myWallet, setMyWallet, walletLoading } = useWallet(
    user.email
  );
  console.log("myWallet:", myWallet);
  const onAddUserSubmit = (data) => {
    setError("");
    console.log("registerWithEmail");
    console.log(data);
    registerWithEmail(
      data.displayName,
      data.email,
      data.host,
      data.password,
      history
    );
    // addUserTodatabase(data.email, data.password,data.host, 'PUT')
    // const userInfo = verifyUserLogin(data.email, data.password);
    console.log("userInfo", data);
  };
  const onUpdateUserSubmit = async (data) => {
    data.email = user.email;
    setError("");
    console.log("onUpdateUserSubmit");
    // registerWithEmail(
    //   data.displayName,
    //   data.email,
    //   data.host,
    //   data.password,
    //   history
    // );
    console.log("SUBMITTED DATA:", { ...data, method: "PUT" });
    const alertMsg = await addUserTodatabase({ ...data, method: "PUT" });
    console.log("alertMsg:", alertMsg);
    if (alertMsg) alert(alertMsg);
    // const userInfo = verifyUserLogin(data.email, data.password);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setError("");
  };

  const getTodaysBalance = () => {
    return parseInt(myWallet?.dayLimit) - sentToday;
  };
  const getMonthlyBalance = () => {
    return parseInt(myWallet?.monthLimit) - sentThisMonth;
  };

  const renderModal = (userEmail) => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
                sx={{ mx: "auto" }}
              >
                Manage User
              </Typography>
              <form onSubmit={() => alert("submitted")} className="delay-form">
                <h6>User Email: {userEmail}</h6>
                <input
                  type="number"
                  {...register("dayLimit")}
                  placeholder="Daily Limit"
                />
                <input
                  type="number"
                  {...register("monthlyLimit")}
                  placeholder="Monthly Limit"
                />
                <input
                  type="date"
                  {...register("expiration")}
                  placeholder="Expire Date"
                />
                <Button
                  type="submit"
                  variant="success"
                  className="w-100"
                  color="success"
                >
                  Submit
                </Button>
              </form>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h6"
                sx={{ mx: "auto", mt: 1 }}
              >
                Add Balance
              </Typography>
              {/* <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                  supports google csv format
                </Typography> */}
              {/* <a href={demoCSV} download="demoCSV.csv"> Download Demo CSV (Supported format)</a> */}
              <div className="App">
                <input
                  type="number"
                  variant="contained"
                  color="success"
                  onChange={(e) => {
                    // const files = e.target.files;
                    console.log("files", e.target.value);
                  }}
                />
              </div>
              <Button
                onClick={() => null}
                variant="success"
                color="success"
                size="small"
              >
                Add Balance
              </Button>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  };

  return (
    <Container className="py-5">
      <Paper elevation={2} className="bg-light p-5 py-2 mx-5 mb-3">
        <div></div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // p: 1,
            // m: 1,
            // bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <h3>Account Information</h3>
          {!showUpdate && (
            <button
              onClick={() => setShowUpdate(true)}
              className="btn btn-success btn-sm mb-2"
            >
              <DriveFileRenameOutlineIcon /> UPDATE ACCOUNT
            </button>
          )}
        </Box>
        {!showUpdate && (
          <div>
            <h6>Name: {user?.displayName}</h6>
          </div>
        )}
        {userAccount?.role === "admin" ? (
          <>
            <h6>Email: {user.email}</h6>
            <h6>Role: {userAccount?.role}</h6>
          </>
        ) : (
          <h6>Role: {userAccount?.role || "USER"}</h6>
        )}
        {showUpdate && (
          <Box sx={{ bgcolor: "background.paper", p: 3, pb: 1 }}>
            <form onSubmit={handleSubmit(onUpdateUserSubmit)}>
              <Box sx={{ width: 1, px: 1 }}>
                <label>User Name:</label>
                <input
                  placeholder="Name"
                  {...register("name", { required: false })}
                />
                <label>Email:</label>
                <input
                  placeholder={user?.email || "example@yourdomain"}
                  {...register("email", { required: true })}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  // p: 1,
                  // m: 1,
                  // bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Box sx={{ width: 1, px: 1 }}>
                  <div>
                    <label>Host:</label>
                    <input
                      placeholder="Host"
                      {...register("host", { required: false })}
                    />
                  </div>

                  {/* <div>
                                <label> NID Number:</label>
                                <input placeholder="NID Number" {...register("nid", { required: true })} />
                            </div> */}
                  <label>Email Password:</label>
                  <input
                    type="password"
                    {...register("password", { required: false })}
                    placeholder="password"
                  />
                </Box>
                <Box sx={{ width: 1, px: 1 }}>
                  <div>
                    <label>Client id:</label>
                    <input
                      placeholder="client_id"
                      {...register("client_id", { required: false })}
                    />
                  </div>
                  <div>
                    <label>Client secret:</label>
                    <input
                      placeholder="client_secret"
                      {...register("client_secret", { required: false })}
                    />
                  </div>
                </Box>
              </Box>

              {/* errors will return when field validation fails  */}
              {errors.exampleRequired && <p>This field is required</p>}
              {/* {error && <Alert severity="error" sx={{ mt: 2 }}>{error} <CloseIcon onClick={() => setError('')} /></Alert>} */}
              <Box
                className="d-grid gap-2 my-3"
                // sx={{
                //   display: "flex",
                //   flexDirection: "row",
                //   justifyContent: "space-between",
                //   // p: 1,
                //   // m: 1,
                //   // bgcolor: "background.paper",
                //   borderRadius: 1,
                // }}
              >
                <Button
                  variant="success"
                  size="sm"
                  type="submit"
                  className="mb-0"
                >
                  Submit
                </Button>
                {isUploading && (
                  <Stack sx={{ width: "100%", color: "grey.500" }} spacing={0}>
                    <LinearProgress
                      color="success"
                      style={{ padding: "0", marginTop: "-3px" }}
                    />
                  </Stack>
                )}
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="mb-0"
                  onClick={() => setShowUpdate(false)}
                >
                  Cancel
                </Button>
              </Box>

              {/* <input type="submit" placeholder="Login" /> */}
            </form>

            {/* {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>} */}
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
                {/* <strong>Try again!</strong> */}
              </Alert>
            )}
            {alertMessage && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {alertMessage} — <strong>check it out!</strong>
              </Alert>
            )}
          </Box>
        )}
      </Paper>
      {emailLogs ? (
        <Row className="">
          <Col xs={12} md={6} className="">
            <Paper elevation={2} className="bg-light p-5 py-2 ms-5 me-0 mb-3">
              <h3>Wallet</h3>
              <TextItem
                value={monthlyLimit - emailLogs?.totalSentCount}
                label="Current Balance"
              />
              <TextItem value={myWallet?.expiration} label="Expiration" />
              <TextItem value={getTodaysBalance()} label="Today's Balance" />
              <TextItem
                value={myWallet?.dayLimit ? myWallet?.dayLimit : "500"}
                label="Daily Limit"
              />
            </Paper>
          </Col>
          <Col xs={12} md={6} className="">
            <Paper elevation={2} className="bg-light p-5 py-2 me-5 mb-3">
              <h3>Email Logs</h3>
              <TextItem
                value={emailLogs?.totalSentCount}
                label="Total Email Sent"
              />
              <TextItem value={sentToday} label="Sent Today" />
              <TextItem value={sentThisMonth} label="Sent This Month" />
              <TextItem
                value={
                  emailLogs?.totalFailCount ? emailLogs?.totalFailCount : 0
                }
                label="Total Sent Failed"
              />
            </Paper>
          </Col>
        </Row>
      ) : loading ? (
        <LoadingComponent />
      ) : (
        <Alert severity="error" className="p-5 m-5">
          <AlertTitle>Error</AlertTitle>
          <strong>Some thing Went Wrong!</strong>
        </Alert>
      )}

      {userAccount?.role === "admin" && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="bg-light mx-5 px-5 mb-3"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            // bgColor="success"
          >
            <Typography
              variant="h3"
              style={{ fontSize: "28px", fontWeight: "600" }}
            >
              Manage Users
            </Typography>
            {/* <Typography variant="h5" className="text-center pb-2" style={{ fontSize: '18px' }}>Add New User</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Box className="pb-0">
              {/* <div className="bg-white"> */}
              <Table striped hover bordered>
                <thead className="text-center">
                  <tr>
                    <th colSpan="2">User Email</th>
                    <th>Daily Limit</th>
                    <th>Monthly Limit</th>
                    <th>Expiration</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {
                    // show mails in the sent mail box
                    loading ? (
                      <LoadingComponent />
                    ) : allUserWalletLogs?.length > 0 ? (
                      allUserWalletLogs?.map(
                        (mail, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td colSpan="2">{mail.userEmail}</td>
                                <td>{mail?.dayLimit}</td>
                                <td>{mail?.monthlyLimit}</td>
                                <td>{mail?.expiration}</td>
                                {/* <td>
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                      handleOpen();
                                    }}
                                  >
                                    Update
                                  </Button>
                                </td> */}
                                <ManageUser mail={mail} />
                              </tr>
                              {/* {renderModal(mail.userEmail)} */}
                            </>
                          );
                        }
                        // eslint-disable-next-line react/jsx-pascal-case
                      )
                    ) : (
                      <Box>Could Not Connect To The Server</Box>
                    )
                  }
                </tbody>
              </Table>

              {/* </div> */}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {userAccount?.role === "admin" && (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="bg-light mx-5 px-5 mb-3"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            bgColor="success"
          >
            <Typography
              variant="h3"
              style={{ fontSize: "28px", fontWeight: "600" }}
            >
              Add User
            </Typography>
            {/* <Typography variant="h5" className="text-center pb-2" style={{ fontSize: '18px' }}>Add New User</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Box className="pb-0">
              <form onSubmit={handleSubmit(onAddUserSubmit)}>
                <div>
                  <label>User Name:</label>
                  <input
                    placeholder="Name"
                    {...register("displayName", { required: true })}
                  />
                </div>
                <div>
                  <label>Host:</label>
                  <input
                    placeholder="Host"
                    {...register("host", { required: true })}
                  />
                </div>
                <label>Email:</label>
                <input
                  placeholder="example@yourdomain"
                  {...register("email", { required: true })}
                />
                {/* <div>
                                <label> NID Number:</label>
                                <input placeholder="NID Number" {...register("nid", { required: true })} />
                            </div> */}
                <label>Password:</label>
                <input
                  type="password"
                  {...register("password", { required: true })}
                  placeholder="password"
                />

                {/* errors will return when field validation fails  */}
                {errors.exampleRequired && <p>This field is required</p>}
                {/* {error && <Alert severity="error" sx={{ mt: 2 }}>{error} <CloseIcon onClick={() => setError('')} /></Alert>} */}
                <div className="d-grid gap-2 my-3">
                  <Button
                    variant="success"
                    size="sm"
                    type="submit"
                    className="mb-0"
                  >
                    Submit
                  </Button>
                  {isUploading && (
                    <Stack
                      sx={{ width: "100%", color: "grey.500" }}
                      spacing={0}
                    >
                      <LinearProgress
                        color="success"
                        style={{ padding: "0", marginTop: "-3px" }}
                      />
                    </Stack>
                  )}

                  {/* {
                            isLoading ? <Skeleton variant="rectangular" color="error" height={50} sx={{ bgcolor: "blue.900" }} /> : <Button variant="light" size="sm" type="submit" className="">Login</Button>
                    } */}
                  {/* <LoadingButton
                        // onClick={handleClick}
                        type="submit"
                        loading={setIsLoading}
                        loadingIndicator="Loading..."
                        variant="outlined"
                    >
                        Fetch data
                    </LoadingButton> */}
                </div>

                {/* <input type="submit" placeholder="Login" /> */}
              </form>

              {/* {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>} */}
              {error && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                  {/* <strong>Try again!</strong> */}
                </Alert>
              )}
              {alertMessage && (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  {alertMessage} — <strong>check it out!</strong>
                </Alert>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Container>
  );
};

export default Account;
