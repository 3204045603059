import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './CreateTemplate.css'
// import './../../Templates/Template/Demo-Template/template.css';
// import './templateForm.css'
// import './../../Templates/Template/templateForm.css';
// import useTemplates from '../../../hooks/useTemplates';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Grid from '@mui/material/Grid';
import useTemplates from '../../hooks/useTemplates';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Container, Paper, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Button, Input, TextField } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import demImg from '../../assets/administrator.png'
import useAuth from '../../hooks/useAuth';
const CreateTemplate = () => {
    const { templates, loading } = useTemplates();
    const [head, setHead] = useState('Hello');
    const [templateType, setTemplateType] = useState('raw');
    const [subject, setSubject] = useState('Your Dream Home Is Still Available For Rent!!');
    const [hyperLink, setHyperLink] = useState('http://email.dabf.shop/');
    const [button_title, setButton_title] = useState('Visit Us');
    const [footer, setFooter] = useState('Regards');
    const [body, setBody] = useState('Email Body Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus excepturi doloremque et explicabo animi cum pariatur inventore illum repudiandae nihil.');
    const [file, setFile] = useState(null);
    const [success, setSuccess] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const [companyName, setCompanyName] = useState('Company Name');
    const [imageUrl, setImageUrl] = useState(demImg);
    const [promotionTitle, setPromotionTitle] = useState('Promotion Title');
    const [promotionSubtitle, setPromotionSubtitle] = useState('About The Product or Service');
    const [promotionDetails, setPromotionDetails] = useState('Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea repellendus itaque autem blanditiis ut voluptatum quasi accusantium veniam sit quod!');
    const [expanded, setExpanded] = useState(true);
    const { user } = useAuth();
    const demoData = {
        template_name: 'Template Name', Company_name: 'Company name', promotion_title: 'Promotion Title', promotion_subtitle: 'Promotion Sub-title', promotion_details: 'Details About The Promotion', prodcut_img: '../../assets/administrator.png', head: 'Hello!', body: 'This is a demo Email', hyperLink: 'https://bdemr.com/', footer: 'Thanks', button_title: 'Visit Us'
        , button_link: 'https://bdemr.com/', _id: '000000001'
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : true);
    };
    const onSubmit = data => {
        data.userEmail = user?.email;
        axios.post(`${process.env.REACT_APP_SERVERHOST}:7002/templates`, data)
            .then(res => {
                if (res.data.insertedId) {
                    alert('added successfully');
                    reset();
                }
            })
            .catch(err => console.log(err))
    }
    const handleBasicSubmit = e => {
        console.log('submitted');
        e.preventDefault();
        // if (!file) {
        //     return;
        // }
        console.log('submitted')
        const formData = new FormData();
        formData.append('head', head);
        formData.append('userEmail', user?.email);
        formData.append('body', body);
        formData.append('footer', footer);
        formData.append('subject',subject);
        formData.append('hyperLink',hyperLink);
        formData.append('button_title', button_title);
        // formData.append('file', file);
        formData.append('templateType', templateType);
        formData.append('isDeleted', false);
        for (let i = 0; i < file.length; i++) {
            formData.append(`file${i}`, file[i])
        }
        console.log(formData, 'formData')
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/templates`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                if (data.insertedId) {
                    setSuccess('Template added successfully')
                    console.log('Template added successfully')
                }
                else {
                    console.log('files:', data)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    const onBasicTemplateSubmit = data => {
        console.log('basic temp', data)
    }
    let navigate = useNavigate();
    const changeRoute = () => {
        navigate('/templates');
    }
    return (
        <Container sx={{ p: 1, my: 2 }}>
            <Box>
                <Paper elevation={2} sx={{ p: 1, my: 2 }}>
                        <Row className="m-2">
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col md={12}>
                                        <h2>Create Template {' '} <DriveFileRenameOutlineIcon /></h2>
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={12} md={6} className="p-0 d-flex justify-content-end align-items-center">
                            <button href="/templates" className="btn btn-success" onClick={changeRoute}> All TEMPLATES <Badge badgeContent={templates?.length} color="error">
                                    <NewspaperIcon color="action" />
                            </Badge></button>

                            </Col>
                        </Row>
                </Paper>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ mb: 3, p: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h5">
                            Raw Template

                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>

                            Create Raw Template
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>
                            <Col xs={12} md={12}>
                                <Box>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className="template-container">
                                                <p>{head}</p>
                                                <p>{body}</p>
                                                <p><a href={hyperLink}>{button_title}</a></p>
                                                <p>{footer}</p>
                                                <p></p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className="template-container basic-template">
                                                <h2 className="text-center py-1">Provide the data</h2>
                                                <form onSubmit={handleBasicSubmit}>
                                                <TextField
                                                        sx={{ width: '100%', my: 1 }}
                                                        id="outlined-basic"
                                                        label="Email Subject"
                                                        variant="outlined"
                                                        onChange={e => setSubject(e.target.value)}
                                                    />

                                                    <TextField
                                                        sx={{ width: '100%', my: 1 }}
                                                        id="outlined-basic"
                                                        label="Email Hearder"
                                                        variant="outlined"
                                                        onChange={e => setHead(e.target.value)}
                                                    />
                                                    <label>Email Body:</label>
                                                    <TextareaAutosize
                                                        sx={{ width: '100%', my: 1 }}
                                                        label="Email Body"
                                                        aria-label="Email Body"
                                                        minRows={3}
                                                        // placeholder="Email Body"
                                                        style={{ width: "100%", border: "0.5px solid #909090" }}
                                                        onChange={e => setBody(e.target.value)}
                                                    />
                                                    <TextField
                                                        sx={{ width: '100%', my: 1 }}
                                                        id="outlined-basic"
                                                        label="Hyper Link"
                                                        variant="outlined"
                                                        onChange={e => setHyperLink(e.target.value)}
                                                    />
                                                    <TextField
                                                        sx={{ width: '100%', my: 1 }}
                                                        id="outlined-basic"
                                                        label="Hyper Link Text"
                                                        variant="outlined"
                                                        onChange={e => setButton_title(e.target.value)}
                                                    />


                                                    <TextField
                                                        sx={{ width: '100%', my: 1 }}
                                                        id="outlined-basic"
                                                        label="Email Footer"
                                                        variant="outlined"
                                                        onChange={e => setFooter(e.target.value)}
                                                    />
                                                    <label>Select Attachment:</label>
                                                    <input
                                                        style={{ width: "100%", border: "0.5px solid #909090" }}
                                                        type="file"
                                                        multiple 
                                                        accept="*"
                                                        // variant="contained"
                                                        color="success"
                                                        onChange={(e) => {
                                                            setFile(e.target.files);
                                                            // setFile(e.target.files[0]);
                                                        }}
                                                    />
                                                    <Button variant="contained" type="submit" sx={{ width: '100%', mt: 2 }} style={{ width: "100%", background: "#198754" }}>
                                                        Add Template
                                                    </Button>
                                                </form>
                                                {success && <p style={{ color: 'green' }}>{success}</p>}
                                            </div>

                                        </Col>
                                    </Row>
                                </Box>
                            </Col>
            </Row>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ mb: 3, p: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h5">
                            Html Template

                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>

                            Create Html Template
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>
                <Col xs={12} md={6}>
                    <div class="template-container">
                        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>
                            <tr>
                                <td valign="top" class="bg_white" style={{ padding: "1em 2.5em 0 2.5em" }}>
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td class="logo">
                                                {/* <h1><a href="#">bdemr</a></h1> */}
                                                <Tooltip title="Company Name" placement="bottom-end" arrow>
                                                                <h1><a href={hyperLink}>{companyName}</a></h1>
                                                </Tooltip>

                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td valign="middle" class="hero bg_white" style={{ padding: "2em 0 4em 0" }}>
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td style={{ padding: "0 2.5em", textAlign: "center", paddingBottom: "3em" }}>
                                                <div class="text">
                                                    <h2>{promotionTitle}</h2>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="text-author">
                                                                <img src={imageUrl} alt="" style={{ width: "50%", maxWidth: "600px", height: "auto", margin: "auto", display: "block" }} />
                                                    <h3 class="name" style={{ textAlign: "center" }}>{promotionSubtitle}</h3>
                                                    <span class="position">{promotionDetails}</span>
                                                                <Tooltip title={demoData?.button_link} placement="bottom-end" arrow>
                                                                    <p><a href={demoData?.button_link} class="btn btn-success">{button_title}</a></p>
                                                    </Tooltip>

                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                                    {/* <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>
                            <tr>
                                <td valign="middle" class="bg_light footer email-section">
                                    <table>
                                        <tr>
                                            <td valign="top" width="34%" style={{ paddingTop: "20px" }}>
                                                <table role="presentation" width="100%">
                                                    <tr>
                                                        <td style={{ textAlign: "left", paddingRight: "10px" }}>
                                                            <h3 class="heading">SUPPORT | VIEW ONLINE</h3>
                                                            <p>You received this transactional email because it contains information about your relationship with BDEMR.</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td valign="top" width="33.333%" style={{ paddingTop: "20px" }}>
                                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                    <tr>
                                                        <td style={{ textAlign: "left", paddingLeft: "5px", paddingRight: "5px" }}>
                                                            <h3 class="heading">Contact Info</h3>
                                                            <ul>
                                                                <li><span class="text">8505 Wildwood Pl, Surrey, BC V4N 5C5, Canada</span></li>
                                                                <li><span class="text">+2 392 3929 210</span>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_light" style={{ textAlign: "center" }}>
                                    <p>No longer want to receive these email? You can <a href="#">Unsubscribe here</a></p>
                                </td>
                            </tr>
                        </table> */}
                    </div>
                </Col>
                <Col xs={12} md={6}>
                                <div class="template-container">
                        <div className="add-service">
                            <h2 className="text-center py-1">Provide the data</h2>
                            <form onSubmit={handleSubmit(onSubmit)} className="pb-5">
                                <input {...register("template_name", { required: true, maxLength: 300 })} placeholder="Template Name" />
                                <input {...register("Company_name", { required: true, maxLength: 300 })} placeholder="Company Name" onChange={event => setCompanyName(event.target.value)} />
                                <input {...register("promotion_title", { required: true, maxLength: 300 })} placeholder="Promotion Title" onChange={event => setPromotionTitle(event.target.value)} />
                                            <input type="text" {...register("prodcut_img")} placeholder="http://image url" onChange={event => setImageUrl(event.target.value)} />
                                            <input {...register("promotion_subtitle", { required: true, maxLength: 300 })} placeholder="About the Product" onChange={event => setPromotionSubtitle(event.target.value)} />
                                <textarea type="text" {...register("promotion_details")} placeholder="Promotion Details" onChange={event => setPromotionDetails(event.target.value)} />

                                            <input type="text" {...register("button_title")} placeholder="Button title" onChange={event => setButton_title(event.target.value)} />
                                <input type="text" {...register("button_link")} placeholder="http://Button link" />
                                <input type="submit" />
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
                    </AccordionDetails>
                </Accordion>

            </Box>
        </Container>
    );
};

export default CreateTemplate;


