import React, { useEffect, useState } from 'react';
import { Col, Container, Figure, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import bannerImage from '../../visuals/Email-Gif.gif';
import Login from '../Login/Login/Login';

const Banner = () => {
    const { user } = useAuth();
    const [data, setData] = useState({});
    let navigate = useNavigate();
    const goToDashBoard = () => {
        navigate('/dashboard');
    }
    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_SERVERHOST}:7002/getImapResponse`)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             setData(data);
    //             console.log('data loaded', data);
    //         })
    //         .catch((err) => {
    //             console.log('Error Found: ', err)
    //         });
    // }, []);
    // console.log('setted loaded', JSON.parse(data[0].body[]));
    // console.log('setted loaded', data[0]);
    // console.log(JSON.parse())
    
    return (
        <Container>
            <Row>
                <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                    {/* 
                    <div>
                        <h2>Explore</h2>
                        <h3>With Email Auto Responder</h3>
                        <button className="btn btn-outline-secondary">Explore</button>
                    </div>
 */}

                    {user.email ? 
                    <div>
                        <h2>Explore</h2>
                        <h3>With Email Auto Responder</h3>
                            <button className="btn btn-outline-secondary" onClick={goToDashBoard}>Explore</button>
                    </div>
                        :
                        <Login></Login>
                    }

                </Col>
                <Col xs={12} md={6} className="m-0">
                    <Figure>
                        <Figure.Image
                            // width={}
                            // height={300}
                            alt="171x180"
                            src={bannerImage}
                        />
                        <Figure.Caption>
                            {/* Nulla vitae elit libero, a pharetra augue mollis interdum. */}
                        </Figure.Caption>
                    </Figure>
                </Col>
            </Row>
        </Container>
    );
};

export default Banner;

