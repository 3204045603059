import { useEffect, useState } from "react";
import useAuth from "./useAuth";
// import Swal from "sweetalert2";

const useAccounts = (email) => {
    const [accounts, setAccounts] = useState([]);
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [accountsError, setAccountsError] = useState('');
    const { user } = useAuth();
    console.log('accounts', accounts);
    // const userAccount = accounts?.find(account => account.email === user.email);
    // const status = userAccount?.status;
    // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails`)
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/users/${email}`)
            .then((res) => res.json())
            .then((data) => {
                setAccounts(data);
                setTotalAccounts(data.length);
                console.log('mails api hitteddd');
            })
            .catch((err) => {
                console.log('Error Found: ', err);
                setAccountsError(err.msg);
            });
    }, []);
    const userAccount = accounts?.find(account => account.email === user.email);
    const status = userAccount?.status;
    console.log(accounts, 'from useAccounts')
    return { accounts, totalAccounts, accountsError, userAccount, status };
};

export default useAccounts;
