import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// const EmailDetailed = ({emailData}) => {
const EmailDetailed = (emailData) => {
    console.log('emailData: ', emailData);
    const { id } = useParams();
    const mail = emailData.emailData.mails;
    console.log(mail);
    let senderName;
    let subject;
    let date;
    mail.map((fetchData) => {
        if(fetchData.id === id) {
            console.log(fetchData.payload);
             senderName = fetchData.payload.headers?.find(item => item.name.toLowerCase() === 'from');
        subject = fetchData.payload.headers?.find(item => item.name.toLowerCase() === 'subject');
        date = fetchData.payload.headers?.find(item => item.name === 'Date');
        console.log(senderName, subject, date);
        }
    })
    
    return (
        <Container>
            <Card className="text-center">
                <Card.Header>{senderName?.value}</Card.Header>
                <Card.Body>
                    <Card.Title>{subject?.value}</Card.Title>
                    <Card.Text>
                       {emailData?.snippet}
                    </Card.Text>
                    <Button variant="primary" disabled>Reply</Button>
                </Card.Body>
                <Card.Footer className="text-muted">{date?.value}</Card.Footer>
            </Card>
        </Container>
    );
};

export default EmailDetailed;