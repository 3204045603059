import { Fade, LinearProgress, Modal, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import React from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function ManageUser({ mail }) {
  console.log("mail:", mail);
  const [open, setOpen] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    // bgcolor: "background.paper",
    // border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 10,
  };
  const handleUpdateWallet = (data) => {
    data.userEmail = mail.userEmail;
    let finalData = { ...data };
    let balance = parseInt(finalData.balance);
    finalData.balance = balance;
    finalData.dayLimit = parseInt(data.dayLimit);
    finalData.monthlyLimit = parseInt(data.monthlyLimit);
    setUpdating(true);
    console.log("wallet data:", data);
    console.log("final data:", finalData);
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/wallet`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("response data:", data);
        setUpdating(false);
        window.location.reload();
        // alert("Updated");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setUpdating(false);
      });
  };

  return (
    <td>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => {
          handleOpen();
        }}
      >
        Update
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h5"
              component="h2"
              sx={{ mx: "auto", mb: 1 }}
            >
              Manage: {mail.userEmail}
            </Typography>
            <form
              onSubmit={handleSubmit(handleUpdateWallet)}
              className="delay-form"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h6"
                sx={{ mx: "auto", mt: 1 }}
              >
                Limits & Expirations
              </Typography>
              <label>Day Limit:</label>
              <input
                type="number"
                {...register("dayLimit")}
                placeholder="Daily Limit"
                defaultValue={mail?.dayLimit}
              />
              <label>Monthly Limit:</label>
              <input
                type="number"
                {...register("monthlyLimit")}
                placeholder="Monthly Limit"
                defaultValue={mail?.monthlyLimit}
              />
              <input
                type="date"
                {...register("expiration")}
                defaultValue={mail?.expiration}
                placeholder="Expire Date"
              />
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h6"
                sx={{ mx: "auto", mt: 1 }}
              >
                Add Balance
              </Typography>
              <input
                type="number"
                {...register("balance")}
                placeholder="Balance"
              />
              <Button
                type="submit"
                variant="success"
                className="w-100 mt-2"
                color="success"
              >
                Submit
              </Button>
              {updating && (
                <Stack sx={{ width: "100%", color: "grey.500" }} spacing={0}>
                  <LinearProgress
                    color="success"
                    style={{ padding: "0", margin: "0" }}
                  />
                </Stack>
              )}
              <Button
                onClick={handleClose}
                variant="outline-danger"
                className="w-100 mt-2"
                // color="success"
              >
                Cancel
              </Button>
            </form>

            {/* <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                  supports google csv format
                </Typography> */}
            {/* <a href={demoCSV} download="demoCSV.csv"> Download Demo CSV (Supported format)</a> */}
            {/* <div className="App">
              <input
                type="number"
                variant="contained"
                color="success"
                onChange={(e) => {
                  // const files = e.target.files;
                  console.log("files", e.target.value);
                }}
              />
            </div> */}
            <div className="d-flex justify-content-end mt-2">
              {/* <Button
                onClick={handleClose}
                // variant="success"
                // color="outline-success"
                variant="outline-danger"
                size="small"
                className="me-2"
              >
                Cancel
              </Button> */}
              {/* <Button
                onClick={() => null}
                variant="success"
                color="success"
                size="small"
              >
                Add Balance
              </Button> */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </td>
  );
}
