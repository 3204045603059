const firebaseConfig = {
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_APP_ID


    apiKey: "AIzaSyB7oN6aJIC1LU56j-feTZMFXP3sizDFVao",
    authDomain: "email-auto-responder-1cdbd.firebaseapp.com",
    projectId: "email-auto-responder-1cdbd",
    storageBucket: "email-auto-responder-1cdbd.appspot.com",
    messagingSenderId: "83754592385",
    appId: "1:83754592385:web:09d81873386cccb9504580"
};
export default firebaseConfig;