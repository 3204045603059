import React, { useEffect, useState } from 'react';

const useThreads = () => {
    const [threads, setThreads] = useState();
    // const [isAutoRespond, setIsAutoRespond] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/threads`)
            .then(res => res.json())
            .then(data => setThreads(data))
            .catch((err) => console.log(err));
    }, []);
    console.log('Threads from useThreads', threads);
    // const contactsCount = settings.length;
    return { threads };
};

export default useThreads;