import PublishSharpIcon from '@mui/icons-material/PublishSharp';
import { Badge, LinearProgress, Paper, Stack, TableBody, TableContainer, TablePagination } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { alpha } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import Papa from "papaparse";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, ListGroup, Row, Spinner, Table, Toast } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import useContacts from '../../../hooks/useContacts';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import demoCSV from '../../../assets/demoCSV.csv';
import MaterialTable from 'material-table'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useEffect } from 'react';
import useDataInfo from '../../../database/useDataInfo';
import useDocumentTitle from '../../../hooks/useDocumentTitle';



// table start
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'others',
    numeric: true,
    disablePadding: false,
    label: 'Others',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
    props;
  const createSortHandler = (property) => (event) => {
    // onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Contacts List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <FilterListIcon /> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
// table end

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function csvHandler(data) {
    //Convert and store csv information into a buffer. 
    let bufferString = data.toString();
    //Store information for each individual person in an array index. Split it by every newline in the csv file. 
    let arr = bufferString.split('\n');
    console.log(arr);
    for (let i = 0; i < arr.length; i++) {
        JSON.stringify(arr[i]);
    }
    var jsonObj = [];
    var headers = arr[0].split(',');
    for (var i = 1; i < arr.length; i++) {
        var data = arr[i].split(',');
        var obj = {};
        for (var j = 0; j < data.length; j++) {
            obj[headers[j].trim()] = data[j].trim();
        }
        jsonObj.push(obj);
    }
    console.log('data: ', data);
    // console.log('Contact Data: ', Obj);
}
const Contacts = ({ mails }) => {
  useDocumentTitle('Contacts 👤 | BDEMR Email Autoresponder');
    const [CSVcontacts, setcSVContacts] = useState([]);
  const [isLoading, setIsloading] = useState(false);
    const { contacts, contactsCount } = useContacts();
    // const [showA, setShowA] = useState(true);
    // const [showB, setShowB] = useState(true);
    const [show, setShow] = useState(false);
  const [contactData, setContactData] = useState();
  const [onSiteContact, setOnSiteContact] = useState([]);
  // const { addContactToDB } = useDataInfo();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { user, reload, setReload, contactLoading, setContactLoading } = useAuth();
  let userErEmail = user.email;
  useEffect(() => {
    setIsloading(true);
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts/?email=${userErEmail}`)
      .then(res => res.json())
      .then(data => {
        let emails = data[0]?.emails.filter(u => u.isDeleted !== true);
        setContactData(data)
        setOnSiteContact(data[0]?.emails);
        setTableData(emails);
        setIsloading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log('tableData', tableData);

  const columns = [
    {
      title: "Email", field: "email", sorting: false, filtering: false, editable: true, width: "35%"
    },
  ];
  const handleAdd = () => {
    console.log('handleAdd clicked')
    setContactLoading(true);
    setTableData([]);
    processAddContactToDB(CSVcontacts);
    console.log('CSVcontacts', CSVcontacts);
    setReload(true);
    setOpen(false);
    setIsloading(true);
  }
  const [data, setData] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  const { register, handleSubmit } = useForm();
  const onSingleContactSubmit = newData => {
    // setIsloading(true);
    const data = {
      email: newData.email,
      userEmail: user.email,
      isDeleted: false
    }
    // { data, userEmail: user.email }
    addContactToDB({ data, userEmail: user.email });
    if (tableData?.length >= 0) {
      const newTableData = [...tableData, data]
      setTableData(newTableData);
    }
    else {
      setTableData({ email: data.email, isDeleted: data.isDeleted });
    }
    setReload(true);
    setOpen(false);

    // window.location.reload(false);
  };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = contacts.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event, name) => {
      // setOpen(true);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;

    // const processData = dataString => {
    //     const dataStringLines = dataString.split(/\r\n|\n/);
    //     const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    //     const list = [];
    //     for (let i = 1; i < dataStringLines.length; i++) {
    //         const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    //         if (headers && row.length == headers.length) {
    //             const obj = {};
    //             for (let j = 0; j < headers.length; j++) {
    //                 let d = row[j];
    //                 if (d.length > 0) {
    //                     if (d[0] == '"')
    //                         d = d.substring(1, d.length - 1);
    //                     if (d[d.length - 1] == '"')
    //                         d = d.substring(d.length - 2, 1);
    //                 }
    //                 if (headers[j]) {
    //                     obj[headers[j]] = d;
    //                 }
    //             }

    //             // remove the blank rows
    //             if (Object.values(obj).filter(x => x).length > 0) {
    //                 list.push(obj);
    //             }
    //         }
    //     }

    //     // prepare columns list from headers
    //     const columns = headers.map(c => ({
    //         name: c,
    //         selector: c,
    //     }));

    //     setData(list);
    //     setColumns(columns);
    // }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            // processData(data);
        };
        reader.readAsBinaryString(file);
        csvHandler(file);
  }
  const addContactToDB = (data) => {
    // data = { email: data.email, isDeleted: data.isDeleted, userEmail: data.userEmail }
    console.log('contact::::', data);
    // fetch(`http://localhost:7002/contacts`, {
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        // modifiedCount: 0, upsertedId: null, upsertedCount: 0
        if (data.modifiedCount > 0 || data.upsertedCount > 0) {
          console.log('Contact added Successful', data);
          setContactLoading(false);
          alert('Uploaded Successfully')
          setIsloading(false);
        }
        else {
          console.log('Already exist');
          setContactLoading(false);
          setIsloading(false);
        }
      })
      .catch(err => console.log(err))

    }
  const processAddContactToDB = async data => {
    await addContactToDB({ data, userEmail: user.email });
        // let displayName = '';
        // let array = data[0];
  //   let email = '';
  //   // console.log('data array', data);
  //   // console.log('data.length', data.length);
  //       // let indexOfFirstName = array?.indexOf('Name');
  //   for (let i = 1; i < data.length - 1; i++) {
  //     // console.log('i:', i, data[i]);
  //     if (data[i].length > 0) {
  //       // displayName = data[i][1]
  //       // console.log('data[i]', data[i]);
  //       email = data[i][0]
  //       // console.log(email);
  //       let success = await addContactToDB({
  //         email: email,
  //         userEmail: user.email,
  //         isDeleted: false
  //       });
  //       // console.log('success: ', success);

  //       // else if (i === data.length - 1) alert('already exists');
  //     }

    //   // }
    //   setContactLoading(false);
    // }
  }
  const handleStudentAdd = (data) => {
    // console.log('id::::', data);
    handleOpen();
    const url = 'http://localhost:7002/contacts';
    // axios.post('url', data)
    //   .then(res => {
    //     if (res.data.insertedId) {
    //       alert('added successfully');
    //     }
    //   })
  }
  const handleStudentUpdate = (data) => {
    console.log('data::::', data);
    const tempData = { ...data };
    delete tempData._id;
    console.log('tempData::::', tempData);
    fetch(`http://localhost:5000/students/${data._id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(tempData)
    })
      .then(res => res.json())
      .then(data => {
        if (data.upsertedCount > 0 || data.modifiedCount) {
          alert('Updated Successfully');
        }
        else {
          alert('Something went wrong')
        }
      });
  }
  const handleBulkDelete = (data) => {
    const updatedData = tableData.filter(row => !selectedRows.includes(row));
    console.log('updatedData', updatedData);
    let ids = [];
    const confirmDelete = window.confirm('Are you sure?');
    if (confirmDelete) {
      data.map(item => {
        // const objID = `ObjectId("${item._id}")`
        // ids.push(objID)
        const deleted = removeContact(item);
        // const deleted = removeStudent(item._id);
        console.log('deleted', deleted)
        setTableData(updatedData);
      })
    }

  }

  // updatedData.splice(selectedRow.tableData.id, 1)
  // setTableData(updatedData)
  // setTimeout(() => resolve(), 1000)
  // }
  const removeContacts = () => {
    const confirmDelete = window.confirm('Are you sure?');
    if (confirmDelete) {
      setIsloading(true);
      // backend.email.dabf.shop
      // const url = `http://localhost:7002/contacts/${contactData[0]._id}`;
      const url = `${process.env.REACT_APP_SERVERHOST}:7002/contacts/${contactData[0]._id}`;
      fetch(url, {
        method: 'DELETE',
        // headers: {
        //     'content-type': 'application/json'
        // },
        // body: JSON.stringify(ids)
      })
        .then(res => res.json())
        .then(data => {
          setIsloading(false);
          console.log(data);
          if (data.deletedCount) {
            setTableData([]);
            alert('Deleted')
          }
        })
    }
  }
  const removeContact = (contact) => {
    console.log(contact, contactData[0].userEmail);
    contact['userEmail'] = contactData[0].userEmail;
    // contact['isDeleted'] = true;
    // fetch(`http://localhost:7002/contacts`, {
    const queryData = { isDeleted: contact.isDeleted, email: contact.email, userEmail: contactData[0].userEmail };
    console.log(queryData);
    fetch(`${process.env.REACT_APP_SERVERHOST}:7002/contacts`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(queryData)
    })
      .then(res => res.json())
      .then(data => {
        if (data) {
          console.log('Contact added Successful');
          // setShow(true)
          return true
        }
        else {
          console.log('Already exist')
          return false
        }
      })
      .catch(err => { console.log('Error:', err); alert('Something Went Wrong') });

  }
    return (
        <div className="container border-3 p-2">
            <Row>
          {/* <Col xs={12} md={2} className="p-0">
                        <ListGroup variant="flush">
                            <ListGroup.Item action>Contacts</ListGroup.Item>
                            <ListGroup.Item action>Frequently Contacted</ListGroup.Item>
                        <ListGroup.Item action>
                            <Button onClick={handleOpen} variant="success" size="small" startIcon={<PublishSharpIcon />}>Import</Button>
                        </ListGroup.Item>
                            <ListGroup.Item action>Export</ListGroup.Item>
                            <ListGroup.Item action>Trash</ListGroup.Item>
                            <ListGroup.Item action>{mails.length}</ListGroup.Item>
                        </ListGroup>
                    </Col> */}
          <Col xs={12} md={12} className="p-0">
            <Paper>
              <Row>
                <Col xs={12} md={6} className="p-0">
                  <Container className="sub-title text-left">
                    <Row>
                      <Col md={8}>Contacts {' '}
                        <Badge badgeContent={tableData?.length || 0} color="success">
                          <ContactPageIcon color="action" />
                        </Badge>
                      </Col>

                    </Row>
                  </Container>
                </Col>   
                <Col xs={12} md={4} className="p-0 d-flex justify-content-end align-items-center">
                  <Button onClick={handleOpen} variant="outlined" color="success" startIcon={<PublishSharpIcon />}>Add Contact</Button>
                  <Button variant="outlined" color="error" startIcon={<DeleteIcon />} className="mx-1" onClick={removeContacts}>
                    Delete All
                  </Button>
                </Col> 
              </Row>
            </Paper>
            <div>
              {isLoading && <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={0}>
                <LinearProgress color="success" style={{ padding: "0", margin: "0" }} />
              </Stack>}
            </div>
            <Box sx={{ width: '100%' }}>
              {contactLoading ? <div className="text-center my-5 private-spinner py-5">
                <Spinner variant="success" animation="border" role="status">
                  <span className="visually-hidden">Updating...</span>
                </Spinner>
                <h6>Uploading...</h6>
              </div> : <MaterialTable columns={columns} data={tableData}
                editable={{
                  // onRowAdd: (newRow) => new Promise((resolve, reject) => {
                  //   handleStudentAdd(newRow);
                  //   setTableData([...tableData, newRow]);
                  //   console.log(newRow, 'new');

                  //   setTimeout(() => resolve(), 500)
                  // }),
                  // onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                  //   const updatedData = [...tableData]
                  //   console.log('newRow', newRow);
                  //   handleStudentUpdate(newRow, newRow._id)
                  //   updatedData[oldRow.tableData.id] = newRow
                  //   setTableData(updatedData)
                  //   setTimeout(() => resolve(), 500)
                  // }),
                  onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                    const updatedData = [...tableData]
                    console.log('selectedRow', selectedRow)
                    updatedData.splice(selectedRow.tableData.id, 1)
                    removeContact(selectedRow)
                    // removeStudent(selectedRow._id)
                    setTableData(updatedData)
                    setTimeout(() => resolve(), 1000)

                  })
                }}
                  actions={[
                    // {
                    //   icon: () => <DeleteIcon />,
                    //   tooltip: "Delete Selected Data",
                    //   onClick: (e, data) => handleBulkDelete(data),
                    //   // isFreeAction:true
                    // }
                  ]}
                  onSelectionChange={(selectedRows) => setSelectedRows(selectedRows)}
                  options={{
                    sorting: true, search: true,
                    searchFieldAlignment: "right", searchAutoFocus: true, searchFieldVariant: "standard",
                    filtering: true, paging: true, pageSizeOptions: [10, 20, 25, 50, 100], pageSize: 10,
                    paginationType: "stepped", showFirstLastPageButtons: true, paginationPosition: "both", exportButton: true,
                    exportAllData: true, exportFileName: "TableData", addRowPosition: "first", actionsColumnIndex: -1, selection: false,
                    showSelectAllCheckbox: true, showTextRowsSelected: false, selectionProps: rowData => ({
                      disabled: rowData.age == null,
                      // color:"primary"
                    }),
                    grouping: false, columnsButton: false,
                    rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5" } : null,
                    headerStyle: {
                      background: "#198754", color: "#fff", position: 'sticky', zIndex: 0
                    },
                  }}
                title="Contacts"
                icons={{ Add: () => <AddIcon /> }}
              />
              }
            </Box>

                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                            <Toast.Header>
                                <img
                                    src="holder.js/20x20?text=%20"
                                    className="rounded me-2"
                                    alt=""
                                />
                            <strong className="me-auto">Contact Added</strong>
                            <small>now</small>
                            </Toast.Header>
                        <Toast.Body>Succefull!</Toast.Body>
                        </Toast>
                    </Col>
          {/* <Col xs={6}>
                        <Button onClick={() => setShow(true)}>Show Toast</Button>
                    </Col> */}
            </Row>
        <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                <Typography id="transition-modal-title" variant="h5" component="h2" sx={{ mx: "auto" }}>
                  Add Contacts
                </Typography>
                <form onSubmit={handleSubmit(onSingleContactSubmit)} className="delay-form">
                  <p>Add Single Contact</p>
                  <input type="text" {...register("name")} placeholder="Enter Name" />
                  <input type="email" {...register("email")} placeholder="Enter Email" />
                  <Button type="submit" variant="contained" className="w-100" color="success">Submit</Button>
                </form>
                <Typography id="transition-modal-title" variant="h6" component="h6" sx={{ mx: "auto", mt: 1 }}>
                  Add Contacts from CSV File
                </Typography>
                {/* <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                  supports google csv format
                </Typography> */}
                <a href={demoCSV} download="demoCSV.csv"> Download Demo CSV (Supported format)</a>
                            <div className="App">
                                <input
                                    type="file"
                                    accept=".csv,.xlsx,.xls"
                                    variant="contained"
                                    color="success"
                                    onChange={(e) => {
                                        const files = e.target.files;
                                        console.log('files', files);
                                        if (files) {
                                            console.log('files[0]', files[0]);
                                            Papa.parse(files[0], {
                                                complete: function (results) {
                                                    console.log("Finished:", results.data);
                                                    setcSVContacts(results.data);
                                                    // processAddContactToDB(results.data);
                                                }
                                            }
                                            )
                                        }
                                    }}
                                />
                            </div>
                <Button onClick={handleAdd} variant="outlined" color="success" size="small" startIcon={<PublishSharpIcon />}>Add</Button>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        {/* <DataTable
                pagination
                highlightOnHover
                columns={columns}
                data={data}
            /> */}
        </div>
    );
};

export default Contacts;