import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, Route, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';




const PrivateRoute = ({ children, ...rest }) => {
    const { user, isLoading } = useAuth();
    let location = useLocation();

    if (isLoading) {
        return <div className="d-flex justify-content-center">
            {/* <img src={loading} alt="" className="w-25 mx-auto" /> */}
            <Spinner animation="border" variant="success" />
        </div>
    }
    if (user.email) {
        return children;
    }
    return (
        <Navigate to="/" state={{ from: location }} />
    );
};

export default PrivateRoute;