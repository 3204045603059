import React, { useState } from 'react';
// import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './SingleTemplate.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import useTemplates from '../../hooks/useTemplates';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import { Button, Fade, Paper, Stack, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useDataInfo from '../../database/useDataInfo';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const SingleTemplate = ({ temp, setRefresh }) => {
    const [replyNumber, setReplyNumber] = useState(0);
    // const { settings, setSettings } = useSettings();
    const { addSetting } = useDataInfo();

    // console.log('temp', temp)
    // const { templates, loading, setLoading } = useTemplates();
    // console.log('template ', template);
    const { settings, setSettings, defaultTemplate, setDefaultTemplate } = useSettings();

    const { template_name, Company_name, promotion_title, promotion_subtitle, promotion_details, prodcut_img, button_title, body, head, footer, file, hyperLink, subject
        , button_link, _id
    } = temp || {};
    // const onDelaySubmit = data => {
    //     const tempSetting = { ...settings[0] };
    //     delete tempSetting._id;
    //     setDelay(data.delay)
    //     tempSetting['delay'] = data.delay;
    //     addSetting(tempSetting, settings[0]?._id);
    // };
    const handleDefaultTemplateSelection = () => {
        setDefaultTemplate(_id);
        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        tempSetting.defaultTemplate = _id;
        addSetting(tempSetting, settings[0]._id);
        setRefresh({});
    }
    const handleFollowUpSetting = (event) => {

        const tempSetting = { ...settings[0] };
        delete tempSetting._id;
        //     setReplyNumber(event.target.value);
        const replyN = event.target.value;
        if (replyN === 1) tempSetting[`broadCastTemplate`] = _id;
        else tempSetting[`reply${replyN}`] = _id;

        // console.log('InSIDE Handle Followup:', tempSetting);
        addSetting(tempSetting, settings[0]?._id);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        borderRadius: '15px',
        boxShadow: 24,
        p: 4,
        textAlign: 'center'
    };


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    //     console.log('Template ststus', _id, value)
    // };
    const removeTemplate = () => {
        const confirmDelete = window.confirm('Are you sure?');
        if (confirmDelete) {
            // backend.email.dabf.shop
            // const url = `http://localhost:7002/contacts/${contactData[0]._id}`;
            const url = `${process.env.REACT_APP_SERVERHOST}:7002/templates/${_id}`;
            fetch(url, {
                method: 'DELETE',
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    if (data.deletedCount > 0) {
                        window.location.reload(false);
                    }
                    else {
                        alert('Something Went Wrong')
                    }
                })
                .catch(err => console.log(err))
        }
    }
    return (
        <Paper className="" elevation={3} sx={{ p: 1 }}>
            {temp?.templateType !== 'raw' ? <div>
                <span className=""> Template name: {template_name}</span>
                <div className="cards py-3">
                <div className="temp-head">

                    <h2 className="company_name">{Company_name || head}</h2>
                        <h5 className="card-title">{promotion_title ? promotion_title.slice(0, 40) : ''}</h5>
                </div>
                {prodcut_img && <div className="photo">
                    <img className="card-img-top" src={prodcut_img} alt="" />
                </div>}

                    <div className="card-body">
                    <h6>{promotion_subtitle ? promotion_subtitle : ''}</h6>
                    <p className="card-text">{promotion_details ? promotion_details.slice(0, 150) : body}</p>
                        {button_title && <a href={button_link} className="btn btn-success btn-sm">{button_title}</a>}
                </div>
                <p>{footer && footer}</p>
                    <div>
                        <h6>Attachments:</h6>
                        {file && file.map(item => {
                            <div className="attachments">
                                <img className="card-img-top"
                                    style={{ width: '200px', height: '200px' }}
                                    src={`data:image/png;base64,${item.content}`} alt={item.filename} />
                            </div>
                        })}
                    </div>
                    {/* <div>

                        {file && <div className="attachments">
                        <h6>Attachments:</h6>
                        <img className="card-img-top"
                            style={{ width: '200px', height: '200px' }}
                            src={`data:image/png;base64,${file}`} alt="" />
                    </div>}
                    </div> */}
                </div>
            </div>
                :
                <div className="template-container2 bg-light">
                    {/* <h6> <b>Subject:{subject}</b> </h6> */}
                    <p>{head}</p>
                    <div className="email-body">
                        <p>{body.slice(0, 350)}</p>
                    </div>

                    <p><a href={hyperLink}>{button_title || 'Click here to visit'}</a></p>
                    <p>{footer}</p>
                    <p></p>
                    <div>
                        <div>
                            <h6>Attachments:</h6>
                            {typeof file === 'object' ?
                                <div className="d-flex ">
                                    {file.map(item => <div className="attachments">
                                        <img className="card-img-top"
                                            style={{ marginRight: '5px', border: '1px solid gray' }}
                                            src={`data:image/png;base64,${item.content}`} alt={item.filename} />
                                    </div>
                                    )}
                                </div>
                                :
                                <div className="attachments">
                                    <h6>Attachments:</h6>
                                    <img className="card-img-top"
                                        // style={{ width: '200px', height: '200px' }}
                                        src={`data:image/png;base64,${file}`} alt="" />
                                </div>
                            }
                        </div>

                        {/* </div> */}
                    </div>
                </div>
            }
            {template_name === 'DemoTemplate' ? <Button title="this a demo template. add your own template" variant="outlined" color="error" sx={{ m: 1 }} disabled size="small">
                This is a demo template, Create your own templates
            </Button> :
            <Box sx={{ mt: 2 }}>

                <Button variant="outlined" color="success" size="small" sx={{ m: 1 }}><Link to={`/updateTemplate/${_id}`}>
                    Update</Link>
                </Button>
                <Button variant="outlined" color="error" startIcon={<DeleteIcon />} sx={{ m: 1 }} onClick={removeTemplate} size="small">
                    Delete
                </Button>
                <Button variant="outlined" color="success" onClick={handleOpen} sx={{ m: 1 }} size="small" style={{ width: "" }}>
                Set follow up template
                </Button>
                <br />
            {
                _id === defaultTemplate ?
                    <Chip
                        label="Default Template"
                        DoneIcon={<DoneIcon />}
                        variant="outlined"
                            size="small"
                        sx={{ m: 1 }} /> :
                        <Button variant="outlined" color="success" onClick={handleDefaultTemplateSelection} sx={{ m: 1 }} size="small">Set as Default Template</Button>
            }
            </Box>
            }
                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Set for Reply
                        </Typography>
                        {/* {
                            for(let c=2;c<11;c++){
                                settings[0]?.`reply${c}` 
                            }
                        } */}
                        {_id === settings[0]?.reply2 ? <Chip
                            label="reply2"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply3 ? <Chip
                            label="Reply3"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply4 ? <Chip
                            label="Reply4"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply5 ? <Chip
                            label="Reply5"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply6 ? <Chip
                            label="Reply6"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply7 ? <Chip
                            label="Reply7"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply8 ? <Chip
                            label="Reply8"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply9 ? <Chip
                            label="Reply9"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.reply10 ? <Chip
                            label="Reply10"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {_id === settings[0]?.broadCastTemplate ? <Chip
                            label="BroadCast Template"
                            // onClick={handleClick}
                            // onDelete={handleDelete}
                            onDelete={() => { }}
                            color="success"
                            deleteIcon={<DoneIcon />}
                        /> : ''}
                        {/* {_id === defaultTemplate ? <Button variant="contained" color="success" sx={{ my: 2 }} disabled>Default Template
                        </Button> : <Button variant="outlined" color="success" onClick={handleDefaultTemplateSelection} sx={{ my: 2 }}>Set as Default Template
                        </Button>} */}
                        <Box sx={{ minWidth: 80, pt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Follow Up Reply</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={replyNumber}
                                    label="Follow Up Reply"
                                    onChange={handleFollowUpSetting}
                                >
                                    <MenuItem value={1}>Broadcast Template</MenuItem>
                                    <MenuItem value={2}>2nd Reply</MenuItem>
                                    <MenuItem value={3}>3rd Reply</MenuItem>
                                    <MenuItem value={4}>4th Reply</MenuItem>
                                    <MenuItem value={5}>5th Reply</MenuItem>
                                    <MenuItem value={6}>6th Reply</MenuItem>
                                    <MenuItem value={7}>7th Reply</MenuItem>
                                    <MenuItem value={8}>8th Reply</MenuItem>
                                    <MenuItem value={9}>9th Reply</MenuItem>
                                    <MenuItem value={10}>10th Reply</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>


                </Fade>
            </Modal>
        </Paper>

    );
};

export default SingleTemplate;