import AddBoxIcon from '@mui/icons-material/AddBox';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useDataInfo from '../../database/useDataInfo';
import GetTemplate from '../../hooks/GetTemplate';
import useTemplates from '../../hooks/useTemplates';
import { addToDb, getStoredMails, removeFromDb, updateSentStatus, updateStatus } from '../../localStorage/tempDb';
import { addToThreadDb, getStoredThreads } from '../../localStorage/tempThread';
import './Inbox_SingleEmail.css';



const Inbox_SingleEmail = ({ message, handleEmailDetails, isAutoRespond, setShow, threads, defaultTemplate, settings,templates }) => {
    const [refresh, setRefresh] = useState();
    const mail = message.payload;
    const mailStatus = getStoredMails();
    const ThreadCounter = getStoredThreads();
    const { addContactToDB } = useDataInfo();
    var followCounter = ThreadCounter[message.threadId] || 0;
    const currentThread = threads?.find(item => item.threadId === message.threadId);
    
    const delay = settings[0]?.delay;
    const reply2 = settings[0]?.reply2;
    const reply3 = settings[0]?.reply3;
    const reply4 = settings[0]?.reply4;
    const reply5 = settings[0]?.reply5;
    const reply6 = settings[0]?.reply6;
    const reply7 = settings[0]?.reply7;
    const reply8 = settings[0]?.reply8;
    const reply9 = settings[0]?.reply9;
    const reply10 = settings[0]?.reply10;
    let defaultTemplateContent, secondReply, thirdReply, fourthReply, fifthreply, sixthReply, seventhReply, eightthReply, ninthReply, tenthReply;
    // const defaultTemplateContent = templates?.find(t => t._id === defaultTemplate);
    // console.log('defaultTemplateContent  data', defaultTemplateContent);
    // if (!loading) {
    //     const { template_name, Company_name, promotion_title, promotion_subtitle, promotion_details, prodcut_img, button_title
    //         , button_link
    //     } = templates[0] || {};
    // }

    if (followCounter > 0) {
        // var followCounter = ThreadCounter[message.id];
        // console.log('followCounter of', message.threadId, 'is: ', followCounter, currentThread)
    }

    // const msg = useGetTemplate(followCounter, defaultTemplateContent, templates);

    var thread = {
        threadId: message.threadId,
        count: followCounter
    }
    //save to local stoarge
    if (message.labelIds.includes('UNREAD')) {
        addToDb(message.id);

    }
    else {
        //remove from local storage
        if (mailStatus[message.id] === 'READ') {
            removeFromDb(message.id);
            console.log('IN remove from db');
        }
    }

    const userName = `Khaja Sheikh Imran Sabuj`;
    const userEmail = `sabuj.bdemr@gmail.com`;
    let stateClass = 'read singleTableTr';

    if (mailStatus[message.id] === 'UNREAD') stateClass = 'UNREAD singleTableTr';

    if (mail.parts) {
        // console.log('_________mail>parts___________________ ', new Buffer(mail.parts[0].body.data, 'base64').toString());
        // console.log('from Single Mail, message*************************************', mail.parts[1].body);
        // if (mail.parts.length > 0) {
        //     if (typeof mail.parts[0].body?.data === 'String') {
        //         const message = new Buffer(mail.parts[0].body?.data, 'base64').toString();

        //         console.log('mail.message:--> ', message)
        //     }

    }
    if (mail.parts?.length > 1) {
        // element = new Buffer.from(mail.parts[1].body?.data, 'base64').toString();
        // console.log('mail.parts.html:--> ', mail.parts[1].body?.data)
    }
    // }
    // if (mail.parts.length > 1) {
    //     element = new Buffer.from(mail.parts[1].body?.data, 'base64').toString();
    //     // console.log('mail.parts.html:--> ', mail.parts[1].body?.data)
    // }
    // console.log('element: ', element);

    // }
    // console.log('mail>parts: ', mail.parts !== 'undefined' && mail.parts);
    let senderName = mail.headers.find(item => item.name.toLowerCase() === 'from');


    let displayName = 'No Title Found';
    let senderEmail = '';
    if (senderName.value.includes('<')) {
        displayName = senderName.value.split('<')[0];
        const senderEmailStr = senderName.value.split('<')[1];
        senderEmail = senderEmailStr.split('>')[0];
    }
    else {
        senderEmail = senderName.value;
    }

    const subject = mail.headers.find(item => item.name.toLowerCase() === 'subject');
    // console.log('subject: ', subject.value);
    const InReplyTo = mail.headers.find(item => item.name === 'Message-ID');
    // console.log('InReplyTo: ', InReplyTo?.value);
    const date = mail.headers.find(item => item.name.toLowerCase() === 'date');
    const references = mail.headers.find(item => item.name.toLowerCase() === 'Message-ID');

    let contact = {
        displayName: displayName,
        email: senderEmail
    }

    //save email address to contact list
    if (message.labelIds.includes('CATEGORY_PERSONAL')) {
        // addToDb(message.id);
        // addContactToDB(contact);

    }
    const handleSendMail = (senderEmail, followCounter) => {
        // console.log('Send clicked', mail, senderEmail);
        // if (senderEmail.includes('noreply')) {
        //     alert('Reply is not allowed');
        // }
        // else {
        //     const url = `${process.env.REACT_APP_SERVERHOST}:7002/respond/${senderEmail}`;
        //     fetch(url, {
        //         method: 'POST',
        //         headers: {
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify(mail)
        //     })
        //         .then(res => res.json())
        //         .then(data => {
        //             if (data) {
        //                 console.log('Sent Successful');
        //             }
        //             else {
        //                 alert('failed')
        //             }
        //         })
        // }
        const msg = GetTemplate(followCounter, settings, templates);
        const data = {
            html: msg,
        }
        const url = `${process.env.REACT_APP_SERVERHOST}:7002/respond/${contact.email}`;
        axios.post(url, data)
            .then(res => {
                if (res.data.insertedId) {
                    alert('added successfully');
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Sent Successful');
                }
                else {
                    alert('failed')
                }
            })
            .catch(err => console.log(err));

    }
    const handleReplyinThread = (threadId) => {
        // if (!loading) {
            console.log('reply clicked', templates);
            const defaultTemplateContent = templates?.find(t => t._id === defaultTemplate);
            const secondReply = templates?.find(t => t._id === reply2);
        // templates?.map(temp => {

        // })
        let sub = subject.value;
        if (!subject.value.includes('Re:')) {
            sub = `Re: ${subject.value}`;
        }
        const msg = GetTemplate(followCounter, settings, templates);
        console.log('msg msg', msg);
            const messages = [
                // `From: NAME <email@email.com>`,
                `From: ${userName} <${userEmail}>`,
                // `To: NAME <email@email.com>`,
                `To: ${senderName.value}`,
                `References: ${InReplyTo?.value}`,
                // 'In-Reply-To: <IN_REPLY_TO>',
                `In-Reply-To: <${InReplyTo?.value}>`,
                'Content-Type: text/html; charset=utf-8',
                `Content-Transfer-Encoding: base64`,
                'MIME-Version: 1.0',
                `Subject: ${sub}`,
                '',
                `<h2 style="color:green;text-align:center;"> Hello, ${displayName}!</h2>
            ${msg} \n `,
                '',
            ];
        if (threadId.includes('noreply')) {
            alert('Reply is not allowed');
        }
        else {
            // updateStatus(message.id);
            setRefresh({});
            const url = `${process.env.REACT_APP_SERVERHOST}:7002/replyinThread/${threadId}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(messages)
            })
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        console.log('Sent Successful, data', data);
                        setShow(true);
                        addToThreadDb(message.threadId, message.id)
                    }
                    else {
                        alert('failed')
                    }
                })
        }
        // }

    }

    // Auto responding :
    const handleAutoRepley = (time) => {
        // if (mailStatus[message.id] === 'UNREAD' && senderEmail.includes('CATEGORY_PERSONAL')) {
        if (mailStatus[message.id] === 'UNREAD' && message.labelIds.includes('CATEGORY_PERSONAL')) {
            // setState('UNREAD');
            // making a delay of 30 seconds to 3 minuits 
            message.status = false;
            console.log('delay time: ', time)
            const delayTime = time || Math.floor((Math.random() * 180000) + 30000);
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            (async () => {
                updateStatus(message.id);
                console.log(senderEmail, '> auto response hitted, waiting');
                await delay(delayTime);
                console.log(senderEmail, ' wait is over ', delayTime);
                updateSentStatus(message.id);
                handleReplyinThread(message.threadId);
                handleMarkRead(message.id);

                await delay(10000);
                console.log(senderEmail, '||| wait is over |||', delayTime);
                handleSendMail(senderEmail, 1)

                await delay(11000);
                console.log(senderEmail, '??? wait is over ???', delayTime);
                handleSendMail(senderEmail, 2)


            })();
            // (async () => {
            //     await delay(40000);
            //     console.log(senderEmail, '||| wait is over |||', delayTime);
            //     handleSendMail(senderEmail, 1)

            // })();
            // (async () => {
            //     await delay(60000);
            //     console.log(senderEmail, '??? wait is over ???', delayTime);
            //     handleSendMail(senderEmail, 2)

            // })();


        }
        else if (mailStatus[message.id] === 'UNREAD') {
            console.log('CATEGORY is not PERSONAL or suitable for reply')
        }
    }
    // console.log(' before handleSendMail call: ', delay);
    if (isAutoRespond) {
        handleAutoRepley(delay * 1000);
    }

    const handleMarkRead = (id) => {
        updateStatus(message.id);
        setRefresh({});
        console.log('before mark read : ', message);
        message['status'] = false;
        stateClass = 'read singleTableTr';
        // console.log('mark read clicked: ', message);
        const url = `${process.env.REACT_APP_SERVERHOST}:7002/mark/respond/${id}`;
        fetch(url, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(mail)
        })
            .then(res => {
                res.json();
                console.log(res)
            })
            .then(data => {
                if (data) {
                    console.log(data)
                    alert('Marked Read');
                }
                else {
                    alert('failed')
                }
            })
            .catch(error => console.log(error))
    }
    return (
        <tr className={stateClass}>
            <td className="text-left">

                {/* <button className="btn btn-succces btn-sm" onClick={addContactToDB}>+</button> */}
                {/* <Link onClick={() => handleEmailDetails(message)} to={`EmailDetailed/${message.id}`}>
                </Link> */}

                <OverlayTrigger
                    // key='bottom'
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-bottom`}>
                            Add to Contact list
                            {/* <strong>Add to Contact list</strong> */}
                        </Tooltip>
                    }
                >
                    {/* <span onClick={() => handleSendMail(senderName)} className="me-2">{element} </span> */}
                    <AddBoxIcon style={{ fontSize: 24, marginRight: '20px', color: "#198754" }} onClick={() => addContactToDB(contact)} />
                </OverlayTrigger>


                {/* <button className="btn btn-succces btn-sm" onClick={() => addContactToDB(contact)}>+</button> */}
                <Link onClick={() => handleEmailDetails(message)} to={`/inbox/EmailDetailed/${message.id}`}>
                    <strong>{displayName.slice(0, 25)}</strong>
                </Link>
                <small> {senderEmail}</small>
            </td>

            <td colSpan="3" className="mail-subject">{subject.value.slice(0, 40)}- {message?.snippet.slice(0, 40)}</td>
            <td><small>{date.value.slice(4, 16)}</small></td>
            <td>
                <OverlayTrigger
                    // key='bottom'
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-bottom`}>
                            <strong>Send message</strong>
                        </Tooltip>
                    }
                >
                    {/* <span onClick={() => handleSendMail(senderName)} className="me-2">{element} </span> */}
                    <SendIcon style={{ fontSize: 20, marginRight: '20px', color: "#198754" }} title="Auto Response" onClick={() => handleSendMail(senderEmail)} />
                </OverlayTrigger>
                <OverlayTrigger
                    // key='bottom'
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-bottom`}>
                            <strong>Send Repley in thread</strong>
                        </Tooltip>
                    }
                >
                    {/* <span onClick={() => handleReplyinThread(message.threadId)} className="me-2">{element} </span> */}
                    <ReplyIcon style={{ fontSize: 20, marginRight: '20px', color: "#198754" }} title="Auto Response" onClick={() => handleReplyinThread(message.threadId)} />


                </OverlayTrigger>
                {stateClass === 'UNREAD singleTableTr' ?
                    <MarkEmailUnreadTwoToneIcon style={{ fontSize: 20, marginRight: '20px', color: "#198754" }} title="Mark Unread" onClick={() => handleMarkRead(message.id)} />
                    :
                    <MarkAsUnreadTwoToneIcon style={{ fontSize: 20, marginRight: '20px', color: "#198754" }} title="Mark Unread" />

                }


            </td>

        </tr>
    );

}
export default Inbox_SingleEmail;