import React from 'react';
import { Container, Dropdown, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import logo from '../../visuals/logo.png';

const Header = () => {
    // const user = { displayName: "Sabuj", email: "sabuj.bdemr@gmail.com" }
    const { user, logOut } = useAuth();
    return (
        <div>
            <div>
                <Navbar collapseOnSelect expand="lg" bg="success" variant="dark" className="nav-bar">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src={logo}
                                // width="100"
                                // height="30"
                                className="d-inline-block align-top"
                            />{' '} <br />
                            <small>Email Auto Responder</small>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                {/* <Nav.Link as={Link} to="/home">Home</Nav.Link>
                                <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                                <Nav.Link as={Link} to="/allWatches">Explore</Nav.Link>
                                {user.email ? <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link> : ''} */}

                            </Nav>
                            <Nav>
                                <Navbar.Collapse className="justify-content-end">
                                    <Navbar.Text>
                                        {user.email ?
                                            <NavDropdown
                                                id="nav-dropdown-dark-example"
                                                title={user.displayName || user.email}
                                                menuVariant="dark"
                                            >
                                                <Dropdown.Item onClick={logOut} href="" active>
                                                    LogOut
                                                </Dropdown.Item>
                                                <NavDropdown.Item href=""><Link to={`/account`} sx={{ m: 1 }}>
                Account</Link></NavDropdown.Item>
                                                {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                                            </NavDropdown>
                                            : <Nav.Link as={Link} to="/">
                                                {/* <span>Login</span> */}
                                            </Nav.Link>}
                                    </Navbar.Text>
                                    <Nav>

                                    </Nav>
                                </Navbar.Collapse>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
};

export default Header;