import { useEffect, useState } from "react";
// import Swal from "sweetalert2";

const useEmails = (label) => {
  const [mails, setMails] = useState([]);
  const [mailCount, setMailCount] = useState(0);
  
  // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails`)
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails/${label}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setMails(data);
  //       setMailCount(data.length);
  //       console.log('mails api hitteddd');
  //     })
  //     .catch((err) => {
  //       console.log('Error Found: ', err)
  //     });
  // }, []);
  // console.log(mails, 'from useEmails')
  const inboxCount = mails.length;
  return { mails, inboxCount };
};

export default useEmails;
