import React, { useEffect, useState } from 'react';

const useInboxEmails = () => {
    const [mongoInbox, setMongoInbox] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVERHOST}:7002/inbox`)
            .then(res => res.json())
            .then(data => setMongoInbox(data))
            .catch((err) => console.log(err));
    }, []);
    // console.log('mongoInbox', mongoInbox);
    return mongoInbox;
};

export default useInboxEmails;