import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Broadcast from "../../assets/broadcast.svg";
import Contact from "../../assets/contact.svg";
import Dashboard from "../../assets/dashboard.svg";
import Home from "../../assets/home-solid.svg";
import Inbox from "../../assets/inbox.svg";
import admin from "../../assets/admin.svg";
//All the svg files
// import logo from "../../assets/logo.svg";
import Sent from "../../assets/sent.svg";
import setting from "../../assets/setting.svg";
import Template from "../../assets/template.svg";


const Container = styled.div`
  position: fixed;
  .active {
    // border-right: 4px solid var(--green);
    border-left: 4px solid white;
    background-color:#1fa868;
  }
  z-index: 1;
`;

const Button = styled.button`
  background-color: var(--green);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;

  display: none;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.8s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  background-color: var(--green);
  width: 3.5rem;
  // height: 50vh;
  // margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  // padding: 1rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--green);

  padding: 2rem 0;

  position: absolute;
  top: 2rem;
  left: 0;

  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;

  display: flex;
  padding-left: 1rem;

  &:hover {
    color: white;
    // background-color:#1fa868;
    background-color:#159153;

  }
  // &:hover {
  //   border-right: 4px solid var(--white);
  //   border-radius: 0 30px 30px 0;
  //   transform: translate(110px,0);
  //   background: #198754;
  //   transition: all 1.5s;

  //   img {
  //     filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
  //       brightness(103%) contrast(103%);
  //     color: white;
  //     transform: rotate(360deg);
  //     transition: all 1.5s;
  //   }
  // }

  img {
    width: 1.2rem;
    height: auto;
    color: white;
    backbround: white;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;

  &:hover {
    // color: red;
    // font-size: 1.1rem;
  }
`;

const Sidebar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const handleMouseLeave = () => {
    setClick(false)
  }

  // const [profileClick, setprofileClick] = useState(false);
  // const handleProfileClick = () => setprofileClick(!profileClick);

  return (
    <Container className="sidebar-div" onMouseEnter={handleClick} onMouseLeave={handleMouseLeave}>
      <Button clicked={click} onClick={() => handleClick()}>
        {/* Click */}
      </Button>
      <SidebarContainer className="">
        {/* <Logo>
          <img src={logo} alt="logo" />
        </Logo> */}
        <SlickBar clicked={click}>
          <Item
            onClick={() => setClick(false)}
            exact
            activeclassname="active"
            to="/"
          >
            <img src={Home} alt="Home" />
            <Text clicked={click}>Home</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            exact
            activeclassname="active"
            to="/account"
          >
            <img src={admin} alt="Admin" />
            <Text clicked={click}>Accounts</Text>
          </Item>
          {/* <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/inbox"
          >
            <img src={Inbox} alt="Inbox" />
            <Text clicked={click}>Inbox</Text>
          </Item> */}
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/mailBox"
          >
            <img src={Inbox} alt="Inbox" />
            <Text clicked={click}>Inbox</Text>
          </Item>
          {/* <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/sent"
          >
            <img src={Sent} alt="Sent" />
            <Text clicked={click}>Sent</Text>
          </Item> */}
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/contacts"
          >
            <img src={Contact} alt="Contacts" />
            <Text clicked={click}>Contacts</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/dashboard"
          >
            <img src={Dashboard} alt="Dashboard" />
            <Text clicked={click}>Dashboard</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/broadcast"
          >
            <img src={Broadcast} alt="Broadcast" />
            <Text clicked={click}>Broadcast</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/setting"
          >
            <img src={setting} alt="Setting" />
            <Text clicked={click}>Setting</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            activeclassname="active"
            to="/templates"
          >
            <img src={Template} alt="Templates" />
            <Text clicked={click}>Templates</Text>
          </Item>
        </SlickBar>
      </SidebarContainer>
    </Container>
  );
};

export default Sidebar;
