import { Alert, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
// import usewatchs from '../../../hooks/usewatchs';
import useTemplates from '../../hooks/useTemplates';
import SingleTemplate from '../SingleTemplate/SingleTemplate';
// import useWatches from '../../hooks/useWatches';
import './AllTemplates.css';
const AllTemplates = ({ setRefresh, templates }) => {
    // const [watchs, setwatchs] = useState([]);
    // if (!templates) {
    // { templates, loading } = useTemplates();
    // }
    const [template, setTemplate] = useState(0);
    // console.log(templates);
    return (
        <Paper className="pb-5" elevation={0}>
            {/* <div className="craete-temp d-flex justify-content-center">
                <a href="/createTemplate" className="btn btn-success">CREATE TEMPLATE</a>
            </div>

            <h2 className="text-center py-2">All Templates!</h2> */}
            <h4 className="text-center">
                {
                    !templates ? <div className="text-center my-5 private-spinner py-5">
                        <Spinner variant="success" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <h6>Loading...</h6>
                    </div> : templates?.length === 0 ? <div className="text-center my-5 private-spinner p-5">
                        <Alert severity="error" style={{ fontWeight: "bold", marginBottom: "10px" }}> <span >No Templates Found </span>

                        </Alert>
                    </div> : ''
                }
                {/* {
                    templates?.length === 0 ?  <div className="text-center my-5 private-spinner py-5">
                        <Spinner variant="success" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <h6>Loading...</h6>
                    </div> : ''
                } */}

            </h4>

            <div className="services-container">
                {

                    templates?.map(temp => <SingleTemplate key={temp._id}
                        temp={temp}
                        template={template}
                        setTemplate={setTemplate}
                        setRefresh={setRefresh}
                    ></SingleTemplate>)



                }

            </div>


        </Paper>
    );
};

export default AllTemplates;