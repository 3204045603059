// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import './App.css';
import Banner from './componenets/Banner/Banner';
import Header from './componenets/Header/Header';
import Home from './componenets/Home/Home/Home';
import Sidebar from "./componenets/Sidebar";
import AuthProvider from './context/AuthProvider';
console.log(process.env.REACT_APP_SERVERHOST);
// REACT_APP_SERVERHOST=backend.email.dabf.shop
function App() {
  // const location = useLocation();
  return (
    <div className="App">
      <AuthProvider>
        <Header></Header>
        <Sidebar />
        <Home></Home>
        <Routes>
          <Route path="/" element={<Banner></Banner>} />
        </Routes>
      </AuthProvider>
     
    </div>
  );
}

export default App;
