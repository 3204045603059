import  useDataInfo  from "../database/useDataInfo";
// const {addThreadCount} = useDataInfo()
const addToThreadDb = (tId, id) => {
    const thread = {
        threadId: tId,
        count: 0
    }
    const exists = getDb();
    let email_threads = {};
    if (!exists) {
        email_threads[tId] = 1;
        thread['count'] = 1;
    }
    else {
        email_threads = JSON.parse(exists);
        if (email_threads[tId]) {
            const newCount = email_threads[tId] + 1;
            email_threads[tId] = newCount;
            thread['count'] = newCount;
            console.log('Exists');
        }
        else {
            email_threads[tId] = 1;
            thread['count'] = 1;
        }
    }
    updateDb(email_threads);
    // addThreadCount(email_threads);
    // addThreadCount(thread);
}

const updateStatus = tId => {
    let email_threads = JSON.parse(getDb());
    email_threads[tId] = 'READ';
    updateDb(email_threads);
}
const updateSentStatus = tId => {
    let email_threads = JSON.parse(getDb());
    email_threads[tId] = 'SENT';
    updateDb(email_threads);
}
const getDb = () => localStorage.getItem('email_threads');

const updateDb = mails => {
    localStorage.setItem('email_threads', JSON.stringify(mails));
}

const removeFromDb = tId => {
    const exists = getDb();
    if (!exists) {

    }
    else {
        const email_threads = JSON.parse(exists);
        delete email_threads[tId];
        updateDb(email_threads);
    }
}

const getStoredThreads = () => {
    const exists = getDb();
    return exists ? JSON.parse(exists) : {};
}

const clearTheLocalMails = () => {
    localStorage.removeItem('email_threads');
}

export { addToThreadDb, removeFromDb, clearTheLocalMails, getStoredThreads, updateStatus, updateSentStatus }