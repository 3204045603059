import React, { forwardRef, useEffect, useState } from 'react';
import './MailBox.css';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Container, Paperimport, Badge, Paper, Button, LinearProgress, Stack } from '@mui/material';
import { Col, Modal, Row, Spinner, Table, Toast } from 'react-bootstrap';
import mailsLoading from '../../visuals/mail-download.gif';
import SingleMailBox from '../SingleMailBox/SingleMailBox';
import axios from 'axios';
import useSettings from '../../hooks/useSettings';
import useTemplates from '../../hooks/useTemplates';
import GetHtmlTemplate from '../../hooks/GetHtmlTemplate';
import useAuth from '../../hooks/useAuth';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { SwipeableDrawer } from '@material-ui/core';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const tableIcons = {
    // Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    // Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Replied: forwardRef((props, ref) => <svg data-testid="MarkEmailReadIcon"  {...props} ref={ref}></svg>),

};
const MailBox = () => {
    useDocumentTitle('Inbox 📥 | BDEMR Email Autoresponder');
    const [countPage, setCountPage] = useState(0)
    const [pageSize, setPageSize] = useState(0);
    const [uidNext, setUidNext] = useState(90);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mailUrl, setMailUrl] = useState(`${process.env.REACT_APP_SERVERHOST}:7002/getImapResponse?_page=${countPage + 1}&_limit=${pageSize}&_uidNext=${uidNext}`)
    const [mails, setMails] = useState([]);
    const { settings, delay, setDelay, isAutoRespond, defaultTemplate, setDefaultTemplate } = useSettings()
    const { templates } = useTemplates();
    const [selectedRow, setSelectedRow] = useState(null);
    const broadCastTemplate = templates?.find(t => t._id === settings[0]?.broadCastTemplate);
    // const broadCastTemplate = templates?.find(t => t._id === settings[0]?.broadCastTemplate) || templates[0];
    const { user, reload, setReload, contactLoading, setContactLoading,totalInboxMailCount, setTotalInboxMailCount } = useAuth();
    console.log('pageCount', countPage);
    let defaultReply = {};
    if (templates?.length) {
        defaultReply = GetHtmlTemplate(broadCastTemplate);
    }
    let url = `${process.env.REACT_APP_SERVERHOST}:7002/getImapResponse?`
    useEffect(() => {
        // setDelay(delayTime);
        // console.log('delay time: ', delay);
        const interval = setInterval(() => {
            // if (isModalON === false) {
            // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/mails/UNREAD`)
            fetch(`${process.env.REACT_APP_SERVERHOST}:7002/getImapResponse?_page=${countPage + 1}&_limit=${pageSize}&email=${user.email}`)
                .then((res) => res.json())
                .then((data) => {
                    // data.sortBy(function (o) { return new Date(o.envelope.date) });
                    console.log('data loaded', data.box.uidNext, uidNext);
                    setMails(data.messagesData);
                    setLoading(false)
                    setContactLoading(false)
                    console.log('data loaded', data);
                    setUidNext(data.box.uidNext);
                    setTotalCount(data.box.exists);
                    setTotalInboxMailCount(data.box.exists);
                    setPageSize(Math.ceil(data.box.exists / 10));
                    // if (true) {
                    //     data?.map(mail => {
                    //         if (!mail.flags.includes('\\Seen')) {
                    //             console.log('includes seen:', mail.uid);
                    //             let contact = mail.envelope.from[0].address;
                    //             let subject = mail.envelope.subject;
                    //             sendReply(contact, subject, mail.uid);
                    //         }
                    //     })
                    // }
                })
                .catch((err) => {
                    console.log('Error Found: ', err)
                });
        }, 30000);
        return () => clearInterval(interval);
    }, [countPage]);
    console.log(mails)
    mails.map(mail=>console.log('1-->', mail.envelope['in-reply-to']));
    mails.map(mail=>console.log('2-->', mail.envelope['message-id']));
    const sendReply = (contact, subject, uid) => {
        console.log('contact', contact)
        const data = {
            html: defaultReply,
            subject: subject,
            contact: { email: contact },
            file: broadCastTemplate?.file,
            uid: uid
        }
        const url = `${process.env.REACT_APP_SERVERHOST}:7002/respond/${contact}`;
        axios.post(url, data)
            .then(res => {
                if (res.data.insertedId) {
                    alert('added successfully');
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Sent Successful');
                }
                else {
                    alert('failed')
                }
            })
            .catch(err => console.log(err));
    }
    mails?.sort((dateA, dateB) => new Date(dateB.envelope.date) - new Date(dateA.envelope.date));

    const columns = [
        {
            title: "ID", field: "uid",
            width: 15,
            grouping: true,
            render: rowData => rowData.uid,

        },
        {
            title: "Sender", field: "envelope.from[0].address", grouping: true,
            render: rowData => rowData.envelope.from[0].address
        },
        {
            title: "Subject", field: "envelope.subject", grouping: true,
            render: rowData => rowData.envelope.subject.slice(0,30)
        },
        {
            title: "Date", field: "envelope.date",
            cellStyle: {
                fontSize: '12px'
            },
            grouping: true,
            render: rowData => rowData.envelope.date,
        },
        {
            title: "Status", field: "Status", grouping: true,
            cellStyle: (e, rowData) => {
                if (rowData.flags.includes('\\Answered')) {
                    return { fontWeight: "bold", color: "#198754" };
                }
            },
            render: rowData => rowData.flags[0]?.slice(1)
        },
    ]
    return (
        <Container className="pb-1">
            <Paper elevation={2} sx={{ py: 1, mt: 1 }}>
                <Row className="m-2">
                    <Col xs={6} md={4}>
                        <Row>
                            <Col md={9} className="sub-title text-left">
                                <h2>Inbox {' '}<Badge badgeContent={totalCount} color="success" className="ms-2">
                                    <MailIcon color="action" />
                                </Badge></h2>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} md={8} className="p-0 d-flex justify-content-end align-items-center">

                        {isAutoRespond ?
                            <>
                                <ScheduleSendIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Auto Response" />
                                <Badge pill bg="success" style={{ marginRight: '20px' }} >{delay} sec</Badge>
                            </>
                            :
                            <CancelScheduleSendIcon style={{ fontSize: 24, marginRight: '20px', color: "#198754" }} title="Auto Response" />

                        }
                    </Col>
                </Row>
            </Paper>
            {totalCount === 0 ? <div className="text-center my-5 private-spinner py-5">
                <Spinner variant="success" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <h6>loading...</h6>
            </div> : <Paper elevation={3} style={{ paddingBottom: '5px', margin: '0 auto' }} > <MaterialTable
                columns={columns} title={`Emails`} style={{ marginTop: "20px" }}
                data={mails}
                icons={tableIcons}
                // data={query =>
                //     new Promise((resolve, reject) => {
                //         console.log('query', query);
                //         //pagination
                //         // let url = mailUrl;
                //         setCountPage(query.page === 1 ? 1 : query.page + 1);
                //         setPageSize(query.pageSize);
                //         // url += `_page=${query.page + 1}&_limit=${query.pageSize}`
                //         // url += `&_limit=${query.pageSize}`
                //         // console.log('mailUrl:', mailUrl)
                //         // setMailUrl(url);
                //         resolve({
                //             data: mails,
                //             page: 1,
                //             totalCount: 20,
                //         });
                //     })
                // }
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                    pageSizeOptions: [10, 20, 25, 50, 100],
                    pageSize: Math.ceil(2.5),
                    headerStyle: {
                        background: "#198754", color: "#fff", position: 'sticky', zIndex: 0
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                    }),
                    tableLayout: "auto",
                    paging: false,
                }}
                // components={{
                //     Toolbar: props => (
                //         <div>
                //             <MTableToolbar {...props} />
                //             <div style={{ padding: '0px 10px' }} className="pagination">
                //                 <NavigateBeforeIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => setCountPage(countPage - 1)} />
                //                 {
                //                     [...Array(pageSize).keys()]
                //                         .map(number => <button
                //                             className={number === countPage ? 'selected' : ''}
                //                             key={number}
                //                             onClick={() => setCountPage(number)}
                //                         >{number === 0 ? number + 1 : number * 10}~{number * 10 + 10}</button>)
                //                 }
                //                 <NavigateNextIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => setCountPage(countPage + 1)} />
                //                 <span>of <b>{totalCount}</b> </span>

                //             </div>
                //         </div>
                //     ),
                // }}
                />


                    <div className="d-flex justify-content-end px-1">
                        <div className="pagination">
                            <NavigateBeforeIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => {
                                if (countPage > 0) { setCountPage(countPage - 1); setLoading(true) }
                            }} />
                            <span>{countPage === 0 ? countPage + 1 : countPage * 10}-{countPage * 10 + 10} of <b>{totalCount} </b>  </span>

                            {/* {
                                [...Array(pageSize).keys()]
                                    .map(number => <Button
                                        className={number === countPage ? 'selected' : ''}
                                        key={number}
                                        onClick={() => { setCountPage(number); setLoading(true) }}
                                    >{number === 0 ? number + 1 : number * 10}~{number * 10 + 10}</Button>)
                            } */}
                            {/* <Button className=""></Button> */}
                            <NavigateNextIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => {
                                if (countPage < pageSize) { setCountPage(countPage + 1); setLoading(true) }
                            }} />

                        </div>


                    </div>
                    <div>
                        {loading && <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="success" style={{ padding: "5px 0" }} />
                        </Stack>}
                    </div>
                    {/* <div className="d-flex justify-content-end px-1">
                        <div className="pagination">
                            <NavigateBeforeIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => setCountPage(countPage - 1)} />
                            {

                                [...Array(pageSize).keys()]
                                    .map(number => <button
                                        className={number === countPage ? 'selected' : ''}
                                        key={number}
                                        onClick={() => setCountPage(number + 1)}
                                    >{number === 0 ? number + 1 : number * 10}~{number * 10 + 10}</button>)
                            }
                            <NavigateNextIcon style={{ fontSize: 24, marginRight: '10px', color: "#198754" }} title="Next" onClick={() => setCountPage(countPage + 1)} />
                            <span>of <b>{totalCount}</b> </span>
                        </div>
                    </div> */}


                </Paper>
            }
        </Container>
    );
};

// export default MailBox;
export default MailBox;