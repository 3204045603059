import React from 'react';
import GetHtmlTemplate from './GetHtmlTemplate';
import useSettings from './useSettings';
// import useGetHtmlTemplate from './useGetHtmlTemplate';

const GetTemplate = (replyCount, settings, templates) => {
    console.log('settings[0]', settings[0]);
    console.log('templates[0]', templates);
    // if (!defaultTemplateContent) window.location.reload(false);
    // const { template_name, Company_name, promotion_title, promotion_subtitle, promotion_details, prodcut_img, button_title
    //     , button_link, _id
    // } = defaultTemplateContent || {};
    const defaultTemplateContent = templates?.find(t => t._id === settings[0]?.defaultTemplate);
    console.log('line 13: defaultTemplateContent', defaultTemplateContent);
    const secondReply = templates?.find(t => t._id === settings[0]?.reply2);
    console.log('line 15: secondReply', secondReply);
    const thirdReply = templates?.find(t => t._id === settings[0]?.reply3);
    const fourthReply = templates?.find(t => t._id === settings[0]?.reply4);
    const fifthReply = templates?.find(t => t._id === settings[0]?.reply5);
    const sixthReply = templates?.find(t => t._id === settings[0]?.reply6);
    const seventhReply = templates?.find(t => t._id === settings[0]?.reply7);
    const eighthReply = templates?.find(t => t._id === settings[0]?.reply8);
    const ninthReply = templates?.find(t => t._id === settings[0]?.reply9);
    const tenthReply = templates?.find(t => t._id === settings[0]?.reply10);
    switch (replyCount) {
        case 1:
            const secondTemplate = GetHtmlTemplate(secondReply);
            return (
                secondTemplate
            );
        case 2:
            // const thirdTemplate = GetHtmlTemplate(settings[0]?.reply3);
            const thirdTemplate = GetHtmlTemplate(thirdReply);
            return (
                // 'This is the 3rd reply \nVisit our websites'
                thirdTemplate
            );
        case 3:
            // const fourthTemplate = GetHtmlTemplate(settings[0]?.reply4);
            const fourthTemplate = GetHtmlTemplate(fourthReply);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                fourthTemplate
            );
        case 4:
            const fifthTemplate = GetHtmlTemplate(fifthReply);
            // const fifthTemplate = GetHtmlTemplate(settings[0]?.reply5);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                fifthTemplate
            );
        case 5:
            const sixthTemplate = GetHtmlTemplate(sixthReply);
            // const sixthTemplate = GetHtmlTemplate(settings[0]?.reply6);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                sixthTemplate
            );
        case 6:
            const seventhTemplate = GetHtmlTemplate(seventhReply);
            // const seventhTemplate = GetHtmlTemplate(settings[0]?.reply7);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                seventhTemplate
            );
        case 7:
            const eighthTemplate = GetHtmlTemplate(eighthReply);
            // const eighthTemplate = GetHtmlTemplate(settings[0]?.reply8);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                eighthTemplate
            );
        case 8:
            const ninthTemplate = GetHtmlTemplate(ninthReply);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                ninthTemplate
            );
        case 9:
            const tenthTemplate = GetHtmlTemplate(tenthReply);
            return (
                // 'This is 4th reply \n we have boundless offerings!'
                tenthTemplate
            );
        default:
            // const defaultReply = GetHtmlTemplate(settings[0]?.defaultTemplate);
            const defaultReply = GetHtmlTemplate(defaultTemplateContent);
            return (
                defaultReply
                // 'This is the 2nd reply \nWelcome to bdemr'
            );
    };
};

export default GetTemplate;