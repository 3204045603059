import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingComponent = () => {
  return (
    <div className="text-center my-5 private-spinner py-5">
      <Spinner variant="success" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <h6>Loading...</h6>
    </div>
  );
};

export default LoadingComponent;
