import React, { useEffect, useState } from 'react';
import useSettings from './useSettings';
import useTemplates from './useTemplates';

const GetHtmlTemplate = (defaultTemplateContent) => {
    // const [defaultTemplateContent, setDefaultTemplateContent] = useState({})
    // useEffect(() => {
    // let defaultTemplateContent = {};
    // console.log(tempId);
    // fetch(`${process.env.REACT_APP_SERVERHOST}:7002/templates/${tempId}`)
    //     .then(res => res.json())
    //     .then(data => {
    //         defaultTemplateContent = data;
    //         console.log(data);
    //     })
    //     .catch((err) => console.log(err));
    // }, []);
    // const GetHtmlTemplate = () => {
    // const { settings, delay, setDelay, isAutoRespond, defaultTemplate, setDefaultTemplate } = useSettings();
    // const { templates, loading } = useTemplates();
    console.log('defaultTemplateContent', defaultTemplateContent)
    // const { template_name, promotion_subtitle, promotion_details, prodcut_img, button_title
    //     , button_link, _id
    // } = defaultTemplateContent || {};
    // const defaultTemplateContent = templates?.find(t => t._id === defaultTemplate);
    // const getDefaultTemplateContent = async function (x) { // async function expression assigned to a variable
    //     console.log('async function  template');
    //     let a = await templates?.find(t => t._id === defaultTemplate);
    //     console.log('a template', a);
    //     return a;
    // };
    // async function firstFunction() {
    //     const defaultTemplateContent = getDefaultTemplateContent();
    //     return;
    // };
    // const defaultTemplateContent = getDefaultTemplateContent();
    // defaultTemplateContent = templates?.find(t => t._id === defaultTemplate);
    const { template_name, Company_name, promotion_title, promotion_subtitle, promotion_details, prodcut_img,head,body,hyperLink,footer, button_title
        , button_link, _id
    } = defaultTemplateContent || {};
    // if (defaultTemplateContent !== 'undefined') {
    //     const Company_name = defaultTemplateContent.company_name || 'Company Name';
    //     const promotion_title = defaultTemplateContent.promotion_title || 'This is Promotion Name';
    // }

    console.log('inside html:::::::::::::', defaultTemplateContent);
    const html = `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700" rel="stylesheet">

    
    </head>
    <body>

    <div className="template-container2">
                                                <p>${head}</p>
                                                <p>${body}</p>
                                                <p><a href=${hyperLink} style={textDecoration:"none"}>Click here to apply</a></p>
                                                <p>${footer}</p>
                                                <p></p>
                                            </div>
    </body >
</html >
    `;
    const html2 = `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700" rel="stylesheet">

    <style type="text/css">
    html,
    body {
    margin: 0 0 !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
    background: #f1f1f1;
    }

    /* Stops email clients resizing small text. */
    * {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    }

    /* Centers email on Android 4.4 */
    div[style*="margin: 16px 0"] {
    margin: 0 !important;
    }

    /* Stops Outlook from adding extra spacing to tables. */
    table,
    td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
    }

    /* Fixes webkit padding issue. */
    table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    margin: 0 auto !important;
    }

    /* Uses a better rendering method when resizing images in IE. */
    img {
    -ms-interpolation-mode: bicubic;
    }

    /* Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
    a {
    text-decoration: none;
    }

    /* A work-around for email clients meddling in triggered links. */
    *[x-apple-data-detectors],  /* iOS */
    .unstyle-auto-detected-links *,
    .aBn {
    border-bottom: 0 !important;
    cursor: default !important;
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    }

    /* Prevents Gmail from displaying a download button on large, non-linked images. */
    .a6S {
    display: none !important;
    opacity: 0.01 !important;
    }

    /* Prevents Gmail from changing the text color in conversation threads. */
    .im {
    color: inherit !important;
    }

    /* If the above doesn't work, add a .g-img class to any image in question. */
    img.g-img + div {
    display: none !important;
    }

    /* Mobile Device */
    /* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
    @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    u ~ div .email-container {
    min-width: 320px !important;
    }
    }
    /* iPhone 6, 6S, 7, 8, and X */
    @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    u ~ div .email-container {
    min-width: 375px !important;
    }
    }
    /* iPhone 6+, 7+, and 8+ */
    @media only screen and (min-device-width: 414px) {
    u ~ div .email-container {
    min-width: 414px !important;
    }
    }
    /* CSS Reset : END  */

    /* Progressive Enhancements : BEGIN  */
    .primary {
    background: #17bebb;
    }
    .bg_white {
    background: #ffffff;
    }
    .bg_light {
    background: #f7fafa;
    }
    .bg_black {
    background: #000000;
    }
    .bg_dark {
    background: rgba(0, 0, 0, 0.8);
    }
    .email-section {
    padding: 2.5em;
    }

    /*BUTTON*/
    .btn {
    padding: 10px 15px;
    display: inline-block;
    }
    .btn.btn-primary {
    border-radius: 5px;
    background: #17bebb;
    color: #ffffff;
    }
    .btn.btn-white {
    border-radius: 5px;
    background: #ffffff;
    color: #000000;
    }
    .btn.btn-white-outline {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    }
    .btn.btn-black-outline {
    border-radius: 0px;
    background: transparent;
    border: 2px solid #000;
    color: #000;
    font-weight: 700;
    }
    .btn-custom {
    color: rgba(0, 0, 0, 0.3);
    text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    font-family: "Poppins", sans-serif;
    color: #000000;
    margin-top: 0;
    font-weight: 400;
    }

    body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.8;
    color: rgba(0, 0, 0, 0.4);
    }

    a {
    color: #17bebb;
    }

    table {
    }
    /*LOGO*/

    .logo h1 {
    margin: 0;
    }
    .logo h1 a {
    color: #17bebb;
    font-size: 24px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    }

    /*HERO*/
    .hero {
    position: relative;
    z-index: 0;
    }

    .hero .text {
    color: rgba(0, 0, 0, 0.3);
    }
    .hero .text h2 {
    color: #000;
    font-size: 34px;
    margin-bottom: 0;
    font-weight: 200;
    line-height: 1.4;
    }
    .hero .text h3 {
    font-size: 24px;
    font-weight: 300;
    }
    .hero .text h2 span {
    font-weight: 600;
    color: #000;
    }

    .text-author {
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-width: 50%;
    margin: 0 auto;
    padding: 2em;
    }
    .text-author img {
    border-radius: 50%;
    padding-bottom: 20px;
    }
    .text-author h3 {
    margin-bottom: 0;
    }
    ul.social {
    padding: 0;
    }
    ul.social li {
    display: inline-block;
    margin-right: 10px;
    }

    /*FOOTER*/

    .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);
    }
    .footer .heading {
    color: #000;
    font-size: 20px;
    }
    .footer ul {
    margin: 0;
    padding: 0;
    }
    .footer ul li {
    list-style: none;
    margin-bottom: 10px;
    }
    .footer ul li a {
    color: rgba(0, 0, 0, 1);
    }

    @media screen and (max-width: 500px) {
    }
    </style>
    </head>
    <body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #f1f1f1;">

    <div style="max-width: 600px; margin: 0 auto;" class="email-container">
    <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
    <tr>
    <td valign="top" class="bg_white" style="padding: 1em 2.5em 0 2.5em;">
        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
                <td class="logo" style="text-align: center;">
                    <h1><a href="#">${defaultTemplateContent?.Company_name || 'Default Company Name'}</a></h1>
                </td>
            </tr>
        </table>
    </td>
    </tr>
            <tr>
    <td valign="middle" class="hero bg_white" style="padding: 2em 0 4em 0;">
        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
                <td style="padding: 0 2.5em; text-align: center; padding-bottom: 3em;">
                    <div class="text">
                        <h2>${defaultTemplateContent?.promotion_title || 'Default Promotion Title'}</h2>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="text-align: center;">
                    <div class="text-author">
                <img src=${defaultTemplateContent?.prodcut_img}>
                        <h3 class="name">${defaultTemplateContent?.promotion_subtitle}</h3>
                <p>${defaultTemplateContent?.promotion_details}</p>
        <p><a href="${defaultTemplateContent?.button_link}" class="btn btn-primary">${defaultTemplateContent?.button_title}</a></p>
                    </div >
                </td >
                </tr >
        </table >
    </td >
    </tr >
    </table >
    <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
        <tr>
            <td valign="middle" class="bg_light footer email-section">
                <table>
                    <tr>
                        <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td style="text-align: left; padding-right: 10px;">
                                        <h3 class="heading">SUPPORT | VIEW ONLINE</h3>
                                        <p>You received this transactional email because it contains information about your relationship with BDEMR.</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td valign="top" width="33.333%" style="padding-top: 20px;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td style="text-align: left; padding-left: 5px; padding-right: 5px;">
                                        <h3 class="heading">Contact Info</h3>
                                        <ul>
                                            <li><span class="text">8505 Wildwood Pl, Surrey, BC V4N 5C5, Canada</span></li>
                                            <li><span class="text">+2 392 3929 210</span></a></li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
        <tr>
            <td class="bg_light" style="text-align: center;">
                <p>No longer want to receive these email? You can <a href="https://bdemr.com/" style="color: rgba(0,0,0,.8);">Unsubscribe here</a></p>
            </td>
        </tr>
    </table>
    </div >
    </body >
</html >
    `;
    return (
        html
    );
};

export default GetHtmlTemplate;