import React, { useState } from 'react';
import { Badge, Nav } from 'react-bootstrap';
import { Link, Route, Routes } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useEmails from '../../../hooks/useEmails';
import Account from '../../Account/Account';
import Broadcast from '../../Broadcast/Broadcast';
import Contacts from '../../Contacts/Contacts/Contacts';
import CreateTemplate from '../../CreateTemplate/CreateTemplate';
import Dashboard from '../../Dashboard/Dashboard';
import EmailDetailed from '../../Email_Detailed/EmailDetailed';
import Inbox from '../../Inbox/Inbox';
import MailBox from '../../MailBox/MailBox';
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import Sent from '../../Sent/Sent';
import Setting from '../../Setting/Setting';
import ContactTable from '../../StudentTable/ContactTable';
import Template from '../../Templates/Template/Template';
import UpdateTemplate from '../../UpdateTemplate/UpdateTemplate';
import './Home.css';

const Home = () => {
    const { user, logOut } = useAuth();
    const [inboxCount, setinboxCount] = useState(0);
    const mails = useEmails('SENT');
    const [emailData, setEmailData] = useState({});
    const [error, setError] = useState('');
    const label = 'INBOX'
    // console.log('Home: ', mails);
    const handleEmailDetails = (data) => {
        // console.log('Inside Home>handle details', data);
        setEmailData(data)
    }
    return (
        <div>
            {/* {user.email && <Nav className="justify-content-center navigation-bar py-2 second-nav" activeKey="/">
                <Nav.Item>
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/inbox">Inbox <Badge pill bg="secondary">{mails?.length}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/sent">Sent</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/contacts">Contacts</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/dashboard" eventKey="disabled">Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/broadcast" eventKey="disabled">Broadcast</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/setting" eventKey="disabled">Setting</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/templates" eventKey="disabled">Templates</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/studentTable" eventKey="disabled">Contact Table</Nav.Link>
                </Nav.Item>
            </Nav>} */}
            <Routes> 
                {/* <Route path="/inbox" element={<Inbox setinboxCount={setinboxCount}></Inbox>}>
                </Route> */}
                {/* <Route path="/mailBox" element={<MailBox setinboxCount={setinboxCount}></MailBox>}>
                </Route> */}
                <Route path="/inbox" element={<PrivateRoute>
                    <Inbox setinboxCount={setinboxCount}></Inbox>
                </PrivateRoute>}>
                </Route>
                <Route path="/mailBox" element={<PrivateRoute>
                    <MailBox setinboxCount={setinboxCount}></MailBox>
                </PrivateRoute>}>
                </Route>
                <Route path="/sent" element={<PrivateRoute>
                    <Sent mails={mails} handleEmailDetails={handleEmailDetails}></Sent>
                </PrivateRoute>} />
                <Route path="/contacts" element={<PrivateRoute>
                    <Contacts mails={mails}></Contacts>
                </PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute>
                    <Dashboard inboxCount={inboxCount}></Dashboard>
                </PrivateRoute>} />
                <Route path="/broadcast" element={<PrivateRoute>
                    <Broadcast></Broadcast>
                </PrivateRoute>} />
                <Route path="/templates" element={<PrivateRoute>
                    <Template></Template>
                </PrivateRoute>} />
                <Route path="/setting" element={<PrivateRoute>
                    <Setting />
                </PrivateRoute>} />
                <Route path="/account" element={<PrivateRoute>
                    <Account />
                </PrivateRoute>} />
                <Route path="/studentTable" element={<ContactTable />}>
                </Route>
                {/* <Route path="/sent" element={<Sent mails={mails} handleEmailDetails={handleEmailDetails}></Sent>} /> */}
                {/* <Route path="/contacts" element={<Contacts mails={mails}></Contacts>} /> */}
                {/* <Route path="/dashboard" element={<Dashboard  inboxCount={inboxCount}></Dashboard>} /> */}
                {/* <Route path="/broadcast" element={<Broadcast></Broadcast>} /> */}
                {/* <Route path="/templates" element={<Template></Template>} /> */}
                <Route path="/createTemplate" element={<CreateTemplate></CreateTemplate>} />
                {/* <Route path="/setting" element={
                    <Setting />
                }>
                </Route> */}
                {/* <Route path="/" element={<App/>} /> */}

                <Route path="inbox/EmailDetailed/:id" element={<EmailDetailed emailData={mails}></EmailDetailed>} />
                <Route path="inbox/about/:id" element={<EmailDetailed emailData={mails}></EmailDetailed>} />
                <Route path="updateTemplate/:id" element={<UpdateTemplate></UpdateTemplate>} />

            </Routes> 
        </div>
    );
};

export default Home;